import React, { useState, useEffect } from "react";
import { baseUrl } from "../../../../services/config";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { Field, useFormik, Form, Formik } from "formik";
import * as Yup from "yup";
import { Col } from "react-bootstrap";
import { Table, Modal, Alert } from "react-bootstrap";
import {
  Input,
  DatePickerField,
  Select,
} from "../../../../_metronic/_partials/controls";
import { values } from "lodash-es";
import { PremissionChecker } from "../../../../services/PremissionChecker";

const EditDefineSallary = ({ staffDetails, show, reload }) => {
  const alert = useAlert();
  const authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);

  const [loading, setloading] = useState(false);
  const [designation_id, setdesignation_id] = useState(
    staffDetails.designation_id
  );
  const [pay_scale_id, setpay_scale_id] = useState(staffDetails.pay_scale_id);
  const [qualification, setqualification] = useState(
    staffDetails.qualification
  );
  const [account_no, setaccount_no] = useState(staffDetails.account_no);
  const [global_bank_id, setglobal_bank_id] = useState(
    staffDetails.global_bank_id
  );

  const [isPreviewEnable, setisPreviewEnable] = useState(false);
  const [previewValue, setpreviewValue] = useState({});
  const [salery_days, setsalery_days] = useState(staffDetails.salery_days);
  const [payment_type, setpayment_type] = useState(staffDetails.payment_type);
  const [hifz, sethifz] = useState("");
  const [hostel, sethostel] = useState("");
  const [college, setcollege] = useState("");
  const [additional, setadditional] = useState("");
  const [increment, setincrement] = useState("");
  const [second_shift, setsecond_shift] = useState("");
  const [ugs, setugs] = useState("");
  const [other, setother] = useState("");
  const [hod, sethod] = useState("");
  const [science, setscience] = useState("");
  const [extra_period, setextra_period] = useState("");
  const [extra_coaching, setextra_coaching] = useState("");
  const [convance, setconvance] = useState("");
  const [eobi, seteobi] = useState("");
  const [income_tax, setincome_tax] = useState("");
  const [insurance, setinsurance] = useState("");
  const [van_charge, setvan_charge] = useState("");

  const [otherallowance, setotherallowance] = useState("");
  const [designations, setdesignations] = useState([]);
  const [AllPayScales, setAllPayScales] = useState([]);
  const [bankList, setbankList] = useState([]);
  const [showerr, setshowerr] = useState(false);
  const [showerror, setshowerror] = useState("Please Fill all Fields");
  const [alreadygeneratedsalary, setalreadygeneratedsalary] = useState({});

  const [allsubaccounts, setallsubaccounts] = useState([]);
  //   const [selectedbankcateogry, setselectedbankcateogry] = useState([]);
  const [selectedBankCateogry, setSelectedBankCateogry] = useState(
    staffDetails.bank_account_id
  );
  const [selectedBank, setSelectedBank] = useState([]);
  const [resetCheck, setResetCheck] = useState(false);

  // initialValues=  {
  //   issubmitted: false,

  //   // designation_id: staffDetails.designation_id,
  //   pay_scale_id: staffDetails.pay_scale_id == null ? "" : staffDetails.pay_scale_id,
  //   // qualification: staffDetails.qualification,
  //   // account_no: staffDetails.account_no,

  //   // global_bank_id: staffDetails.global_bank_id,
  //   // salery_days: staffDetails.salery_days,
  //   // payment_type: staffDetails.payment_type,
  //   hifz: "",

  //   hostel: "",
  //   college: "",
  //   additional_allowance: "",
  //   increment: "",

  //   second_shift: "",
  //   ugs: "",
  //   hod: "",
  //   other_allowance: "",

  //   science: "",
  //   extra_period: "",
  //   extra_coaching: "",
  //   convance: "",

  //   eobi: "",
  //   income_tax: "",
  //   insurance: "",
  //   van_charge: "",
  //   other: "",
  // }

  let initialValues = {
    issubmitted: false,

    basic: staffDetails.basic_pay,
    salary_month: staffDetails.salary_month,
    id: staffDetails.id,
    hifz: staffDetails.hifz,

    hostel: staffDetails.hostel,
    college: staffDetails.college,
    additional_allowance: staffDetails.additional_allowance,
    increment: staffDetails.increment,

    second_shift: staffDetails.second_shift,
    ugs: staffDetails.ugs,
    hod: staffDetails.hod,
    other_allowance: staffDetails.other_allowance,

    science: staffDetails.science,
    extra_period: staffDetails.extra_period,
    extra_coaching: staffDetails.extra_coaching,
    convance: staffDetails.convance,
    insurance_cont: staffDetails.insurance_cont,

    eobi: staffDetails.eobi,
    eobi_payments: staffDetails.eobi_payments,
    income_tax: staffDetails.income_tax,
    insurance: staffDetails.insurance,
    van_charge: staffDetails.van_charge,
    other: staffDetails.other,
    other_deduction: staffDetails.other_deduction,
    gp_fund: staffDetails.gp_fund,
    gpf_cont: staffDetails.gpf_cont,
    gpf_return: staffDetails.gpf_return,
    welfare_fund: staffDetails.welfare_fund,
    days: staffDetails.days,
    child_fee_deduction: staffDetails.child_fee_deduction,
    loan_refund: staffDetails.loan_refund,
    // reset: resetCheck,
  };
  //   console.log(initialValues);

  // console.log("initialValues==?", initialValues)

  console.log("staff=>>", initialValues.bank_account_id);
  useEffect(() => {
    getSalaryDetail();
    getAlldesignations();
    getAllPayScales();
    getAllBankList();
  }, []);

  const getSalaryDetail = () => {
    fetch(`${baseUrl}/emp-salary-detail/${staffDetails.employee_id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.metadata) {
          if (data.metadata.success) {
            setalreadygeneratedsalary(data.payload);
          } else {
          }
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAlldesignations = () => {
    fetch(`${baseUrl}/designations`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setdesignations(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const gotoTop = () => {
    window.scrollTo(0, 0);
  };

  const getAllPayScales = () => {
    fetch(`${baseUrl}/payscales`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data.payload, "payscale");
          setAllPayScales(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllBankList = () => {
    fetch(`${baseUrl}/bank_account_category`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        //  setinitialloading(false)
        console.log(data, "fetch data ");
        setbankList(data.payload);

        let mainBank = 0;

        if (selectedBankCateogry ?? false) {
          console.log(selectedBankCateogry);

          data.payload.map((item, index) => {
            item.bank_accounts.map((subitem, index) => {
              if (subitem.id == selectedBankCateogry) {
                setSelectedBank(subitem.bank_account_category_id);
                mainBank = subitem.bank_account_category_id;
              }
            });
          });
        }

        if (mainBank != 0) {
          let bankData = data.payload.filter((item) => item.id == mainBank);

          if (bankData.length != 0) {
            setallsubaccounts([...bankData[0].bank_accounts]);
            // setallsubaccounts(initialValues.bank_account_id);
          } else {
            setallsubaccounts([]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateHandler = (values) => {
    setloading(true);
    const data = {
      // designation_id: parseInt(values.designation_id),
      id: staffDetails.id,
      // salary_month:values.salary_month,
      basic_pay: values.basic == "" ? 0 : parseInt(values.basic),
      hifz: values.hifz,
      hostel: values.hostel,
      college: values.college,
      additional_allowance: values.additional_allowance,
      increment: values.increment,
      second_shift:
        values.second_shift == "" ? 0 : parseInt(values.second_shift),
      ugs: values.ugs == "" ? 0 : parseInt(values.ugs),
      other_allowance: values.other_allowance,
      hod: values.hod == "" ? 0 : parseInt(values.hod),
      science: values.science == "" ? 0 : parseInt(values.science),
      extra_period:
        values.extra_period == "" ? 0 : parseInt(values.extra_period),
      extra_coaching:
        values.extra_coaching == "" ? 0 : parseInt(values.extra_coaching),
      convance: values.convance == "" ? 0 : parseInt(values.convance),
      insurance_cont:
        values.insurance_cont == "" ? 0 : parseInt(values.insurance_cont),
      eobi: values.eobi == "" ? 0 : parseInt(values.eobi),
      eobi_payments:
        values.eobi_payments == "" ? 0 : parseInt(values.eobi_payments),
      income_tax: values.income_tax == "" ? 0 : parseInt(values.income_tax),
      insurance: values.insurance == "" ? 0 : parseInt(values.insurance),
      van_charge: values.van_charge == "" ? 0 : parseInt(values.van_charge),
      other: values.other,
      other_deduction: values.other_deduction,
      gp_fund: values.gp_fund,
      gpf_cont: values.gpf_cont,
      gpf_return: parseInt(values.gpf_return),
      welfare_fund: values.welfare_fund,
      days: values.days,
      child_fee_deduction: parseInt(values.child_fee_deduction),
      loan_refund: values.loan_refund,
      preview: 0,
      bank_account_id: parseInt(selectedBankCateogry),
      reset: resetCheck == false ? 0 : 1,
    };

    // const data = {
    //     id: 1,

    //     salary_month: "2022-04-01",

    //     basic_pay: "16584",

    //     hifz: "20",
    //     hostel: "10",
    //     college: "15",
    //     additional_allowance: "0",
    //     increment: "25",
    //     second_shift: "30",
    //     ugs: "35",
    //     other_allowance: "45",
    //     hod: "40",
    //     science: "50",
    //     extra_period: "55",
    //     extra_coaching: "60",
    //     convance: "0",
    //     eobi: "500",
    //     income_tax: "5000",
    //     insurance: "500",
    //     van_charge: "500",
    //     other_deduction: "500",
    //     gp_fund: "300",
    //     welfare_fund: "100",

    // };
    console.log(data);
    fetch(`${baseUrl}/employeeSalary_update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data);

          setloading(false);
          show(false);
          if (data.metadata) {
            if (data.metadata.success) {
              alert.success("Salary Updated Successfully");
              setloading(false);
              show(false);
              reload();
            } else {
              show(false);
              setloading(false);
              const msgArray = Object.values(data.metadata.message);
              msgArray.map((err) => alert.error(err[0]));
            }
          } else {
            show(false);
            setloading(false);
            // const msgArray = Object.values(data.metadata.message);
            // msgArray.map((err) => alert.error(err[0]));
          }
        });
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
        alert.error(err);
        show(false);
      });
  };

  //start here

  const AppointStaffSchema = Yup.object().shape({
    // designation_id: Yup.string().required("Designation is required"),

    // qualification: Yup.string().required("Qualification is required"),

    // pay_scale_id: Yup.string().required("Pay Scale is required "),

    // account_no: Yup.string().required("account_no is required "),

    // global_bank_id: Yup.string().required("Bank Is Required"),
    // salery_days: Yup.string().required("Salery Days is required "),
    // payment_type: Yup.string().required("Payment Type is required "),
    hifz: Yup.string()
      .notRequired("Hifz  Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),
    basic: Yup.string()
      .notRequired("Hifz  Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),

    hostel: Yup.string()
      .notRequired("Hostel Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),
    college: Yup.string()
      .notRequired("College is notRequired ")
      .matches(/^[0-9\b]+$/, "Should be number"),
    additional_allowance: Yup.string()
      .notRequired("Additional Allowance is notRequired ")
      .matches(/^[0-9\b]+$/, "Should be number"),
    increment: Yup.string()
      .notRequired("Increment  Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),

    second_shift: Yup.string()
      .notRequired("Second Shift Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),
    ugs: Yup.string()
      .notRequired("ugs is notRequired ")
      .matches(/^[0-9\b]+$/, "Should be number"),
    // other_allowance: Yup.string().notRequired("Other Allowance  is notRequired ").matches(
    //   /^[0-9\b]+$/,
    //   "Should be number"
    // ),

    other_allowance: Yup.string()
      .notRequired("Other Allowance is notRequired ")
      .matches(/^[0-9\b]+$/, "Should be number"),

    hod: Yup.string()
      .notRequired("hod  Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),

    science: Yup.string()
      .notRequired("science Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),
    extra_period: Yup.string()
      .notRequired("Extra Period is notRequired ")
      .matches(/^[0-9\b]+$/, "Should be number"),
    extra_coaching: Yup.string()
      .notRequired("Extra Coaching  is notRequired ")
      .matches(/^[0-9\b]+$/, "Should be number"),
    convance: Yup.string()
      .notRequired("Convance  Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),

    insurance_cont: Yup.string()
      .notRequired("Insurance Contribution  Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),

    eobi: Yup.string()
      .notRequired("EOBI Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),
    eobi_payments: Yup.string()
      .notRequired("eobi payments Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),
    income_tax: Yup.string()
      .notRequired("Income Tax is notRequired ")
      .matches(/^[0-9\b]+$/, "Should be number"),
    insurance: Yup.string()
      .notRequired("Insurance is notRequired ")
      .matches(/^[0-9\b]+$/, "Should be number"),
    van_charge: Yup.string()
      .notRequired("Van Charge  Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),
    other: Yup.string()
      .notRequired("other  Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),

    welfare_fund: Yup.string()
      .notRequired("Welfare Fund  Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),
    other_deduction: Yup.string()
      .notRequired("Other Detuction  Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),
    gp_fund: Yup.string()
      .notRequired("GP Fund  Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),
    gpf_cont: Yup.string()
      .notRequired("GPF Contribution  Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),
    gpf_return: Yup.string()
      .notRequired("GP Fund Return  Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),
    child_fee_deduction: Yup.string()
      .notRequired("child fee deduction Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),
    loan_refund: Yup.string()
      .notRequired("Loan Refund Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),
  });

  const createEroors = (errors, touched, isSubmitting, values) => {
    // if (errors.designation_id && touched.designation_id) {
    //   setshowerror(errors.designation_id);
    //   setshowerr(true);
    //   return;
    // }
    // if (
    //   touched.designation_id &&
    //   values.designation_id.toString().length != 0
    // ) {
    //   if (
    //     values.designation_id.toString().length < 1 ||
    //     values.designation_id.toString().length > 30
    //   ) {
    //     setshowerr(true);
    //     setshowerror("Designation id must be between 5 to 10 Charcters");
    //     return;
    //   }
    // }

    // if (errors.pay_scale_id && touched.pay_scale_id) {
    //     setshowerror("Pay scale id is required");
    //     setshowerr(true);
    //     return;
    // }
    // if (errors.qualification && touched.qualification) {
    //   setshowerror(errors.qualification);
    //   setshowerr(true);
    //   return;
    // }

    // if (errors.account_no && touched.account_no) {
    //   setshowerror(errors.account_no);
    //   setshowerr(true);
    //   return;
    // }

    // if (errors.global_bank_id && touched.global_bank_id) {
    //   setshowerror(errors.global_bank_id);
    //   setshowerr(true);
    //   return;
    // }

    // if (errors.salery_days && touched.salery_days) {
    //   setshowerror(errors.salery_days);
    //   setshowerr(true);
    //   return;
    // }

    // if (errors.payment_type && touched.payment_type) {
    //   setshowerror(errors.payment_type);
    //   setshowerr(true);
    //   return;
    // }

    // if (errors.hifz && touched.hifz) {
    //   setshowerror(errors.hifz);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.hostel && touched.hostel) {
    //   setshowerror(errors.hostel);
    //   setshowerr(true);
    //   return;
    // }

    // if (errors.college && touched.college) {
    //   setshowerror(errors.college);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.additional_allowance && touched.additional_allowance) {
    //   setshowerror(errors.additional_allowance);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.increment && touched.increment) {
    //   setshowerror(errors.increment);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.second_shift && touched.second_shift) {
    //   setshowerror(errors.second_shift);
    //   setshowerr(true);
    //   return;
    // }

    // if (errors.ugs && touched.ugs) {
    //   setshowerror(errors.ugs);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.hod && touched.hod) {
    //   setshowerror(errors.hod);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.other_allowance && touched.other_allowance) {
    //   setshowerror(errors.other_allowance);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.science && touched.science) {
    //   setshowerror(errors.science);
    //   setshowerr(true);
    //   return;
    // }

    // if (errors.extra_period && touched.extra_period) {
    //   setshowerror(errors.extra_period);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.extra_coaching && touched.extra_coaching) {
    //   setshowerror(errors.extra_coaching);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.convance && touched.convance) {
    //   setshowerror(errors.convance);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.eobi && touched.eobi) {
    //   setshowerror(errors.eobi);
    //   setshowerr(true);
    //   return;
    // }

    // if (errors.income_tax && touched.income_tax) {
    //   setshowerror(errors.income_tax);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.insurance && touched.insurance) {
    //   setshowerror(errors.insurance);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.van_charge && touched.van_charge) {
    //   setshowerror(errors.van_charge);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.other && touched.other) {
    //   setshowerror(errors.other);
    //   setshowerr(true);
    //   return;
    // }

    setshowerr(false);

    if (Object.keys(errors).length === 0) {
      // if (values.hifz != "") {
      //   console.log("erorrs are not allowed 333");
      if (isSubmitting) {
        if (!values.issubmitted) {
          values.issubmitted = true;

          updateHandler(values);
        }
        // }
      }
    }
  };

  const previewHandle = (values) => {
    console.log("preview values", values);
    setisPreviewEnable(true);

    const data = {
      // designation_id: parseInt(values.designation_id),
      id: staffDetails.id,
      // salary_month:values.salary_month,
      basic_pay: values.basic == "" ? 0 : parseInt(values.basic),
      hifz: values.hifz,
      hostel: values.hostel,
      college: values.college,
      additional_allowance: values.additional_allowance,
      increment: values.increment,
      second_shift:
        values.second_shift == "" ? 0 : parseInt(values.second_shift),
      ugs: values.ugs == "" ? 0 : parseInt(values.ugs),
      other_allowance: values.other_allowance,
      hod: values.hod == "" ? 0 : parseInt(values.hod),
      science: values.science == "" ? 0 : parseInt(values.science),
      extra_period:
        values.extra_period == "" ? 0 : parseInt(values.extra_period),
      extra_coaching:
        values.extra_coaching == "" ? 0 : parseInt(values.extra_coaching),
      convance: values.convance == "" ? 0 : parseInt(values.convance),
      insurance_cont:
        values.insurance_cont == "" ? 0 : parseInt(values.insurance_cont),
      eobi: values.eobi == "" ? 0 : parseInt(values.eobi),
      eobi_payments:
        values.eobi_payments == "" ? 0 : parseInt(values.eobi_payments),
      income_tax: values.income_tax == "" ? 0 : parseInt(values.income_tax),
      insurance: values.insurance == "" ? 0 : parseInt(values.insurance),
      van_charge: values.van_charge == "" ? 0 : parseInt(values.van_charge),
      other: values.other,
      other_deduction: values.other_deduction,
      gp_fund: values.gp_fund,
      gpf_cont: values.gpf_cont,
      gpf_return: values.gpf_return,
      welfare_fund: values.welfare_fund,
      days: values.days,
      child_fee_deduction: values.child_fee_deduction,
      loan_refund: values.loan_refund,
      preview: 1,
      bank_account_id: parseInt(selectedBankCateogry),
      reset: resetCheck == false ? 0 : 1,
    };

    fetch(`${baseUrl}/employeeSalary_update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((data) => {
          console.log(data);
          const obj = {
            net_pay: data.payload.net_pay,
            gross_salary: data.payload.gross_salary,
          };
          setpreviewValue(obj);
        });
      })
      .catch((err) => {
        console.log(err);
        alert.error(err);
        show(false);
      });
  };

  return (
    <div>
      {loading ? (
        <b style={{ color: "green" }}>
          Salary updating Please Wait <span className="spinner-borderd"></span>
        </b>
      ) : (
        <div>
          <div class="container-fluid">
            <div class="row d-flex justify-content-center ">
              <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                <div>
                  <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={AppointStaffSchema}
                    onSubmit={(values) => {
                      updateHandler(values);
                    }}
                  >
                    {(props) => {
                      const {
                        handleSubmit,
                        errors,
                        touched,
                        values,
                        isSubmitting,
                        isValid,
                        handleChange,
                        handleBlur,
                      } = props;

                      // setErrorsLis(errors)
                      createEroors(errors, touched, isSubmitting, values);

                      return (
                        <>
                          <Form
                            onSubmit={handleSubmit}
                            className="form form-label-right"
                          >
                            <div class="row justify-content-between text-left">
                              {/* <div class="form-group col-sm-6 flex-column d-flex ">
                                                                <label class="form-control-label">
                                                                    <b>
                                                                        Pay Scale{" "}
                                                                        <span style={{ color: "red" }}>*</span>
                                                                    </b>
                                                                </label>
                                                                <select
                                                                    class="form-select form-control"
                                                                    name="pay_scale_id"
                                                                    value={values.pay_scale_id}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    aria-label="Default select example"
                                                                >
                                                                    <option selected value="">
                                                                        select Payscale
                                                                    </option>

                                                                    {AllPayScales?.map((payscle) => (
                                                                        <option value={payscle.id}>
                                                                            {payscle.payscale}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {errors.pay_scale_id && touched.pay_scale_id && (
                                                                    <div
                                                                        style={{ color: "#f87d8a" }}
                                                                        className="input-feedback"
                                                                    >
                                                                        Pay scale id is required
                                                                    </div>
                                                                )}
                                                            </div> */}
                            </div>

                            {PremissionChecker(
                              user,
                              "appointed_staff.can_update_allowance"
                            ) ? (
                              <>
                                <h3 className="m-3">
                                  {" "}
                                  Salary Allowances Details
                                </h3>
                                <div class="row justify-content-between text-left">
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>
                                        Basic Salary
                                        <span style={{ color: "red" }}>*</span>
                                      </b>
                                    </label>
                                    <Field
                                      name="basic"
                                      component={Input}
                                      placeholder="Basic Salary"
                                    />
                                  </div>
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>
                                        Hifz
                                        <span style={{ color: "red" }}>*</span>
                                      </b>
                                    </label>
                                    <Field
                                      name="hifz"
                                      component={Input}
                                      placeholder="Hifz"
                                    />
                                  </div>
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>
                                        Hostel{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </b>
                                    </label>
                                    <Field
                                      name="hostel"
                                      component={Input}
                                      placeholder="Hostel"
                                    />
                                  </div>
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>
                                        College
                                        <span style={{ color: "red" }}>
                                          *
                                        </span>{" "}
                                      </b>
                                    </label>
                                    <Field
                                      name="college"
                                      component={Input}
                                      placeholder="College"
                                    />
                                  </div>
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>
                                        Additional{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </b>
                                    </label>
                                    <Field
                                      name="additional_allowance"
                                      component={Input}
                                      placeholder="Additional Allowance"
                                    />
                                  </div>
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>
                                        Increment
                                        <span style={{ color: "red" }}>
                                          *
                                        </span>{" "}
                                      </b>
                                    </label>
                                    <Field
                                      name="increment"
                                      component={Input}
                                      placeholder="increment"
                                    />
                                  </div>
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>
                                        Second Shift
                                        <span style={{ color: "red" }}>
                                          *
                                        </span>{" "}
                                      </b>
                                    </label>
                                    <Field
                                      name="second_shift"
                                      component={Input}
                                      placeholder="Second Shift"
                                    />
                                  </div>
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>
                                        U G S{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </b>
                                    </label>
                                    <Field
                                      name="ugs"
                                      component={Input}
                                      placeholder="UGS"
                                    />
                                  </div>
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>
                                        H O D{" "}
                                        <span style={{ color: "red" }}>*</span>{" "}
                                      </b>
                                    </label>
                                    <Field
                                      name="hod"
                                      component={Input}
                                      placeholder="HOD"
                                    />
                                  </div>
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>
                                        Other allowance{" "}
                                        <span style={{ color: "red" }}>*</span>{" "}
                                      </b>
                                    </label>
                                    <Field
                                      name="other_allowance"
                                      component={Input}
                                      placeholder="Other Allowance"
                                    />
                                  </div>

                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>
                                        Science{" "}
                                        <span style={{ color: "red" }}>*</span>{" "}
                                      </b>
                                    </label>
                                    <Field
                                      name="science"
                                      component={Input}
                                      placeholder="Science"
                                    />
                                  </div>
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>
                                        Extra Period{" "}
                                        <span style={{ color: "red" }}>*</span>{" "}
                                      </b>
                                    </label>
                                    <Field
                                      name="extra_period"
                                      component={Input}
                                      placeholder="Extra Period"
                                    />
                                  </div>
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>
                                        Extra Coaching{" "}
                                        <span style={{ color: "red" }}>*</span>{" "}
                                      </b>
                                    </label>
                                    <Field
                                      name="extra_coaching"
                                      component={Input}
                                      placeholder="Extra Coaching"
                                    />
                                  </div>
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>
                                        Convance
                                        <span style={{ color: "red" }}>
                                          *
                                        </span>{" "}
                                        (No Effect by Days)
                                      </b>
                                    </label>
                                    <Field
                                      name="convance"
                                      component={Input}
                                      placeholder="Convance"
                                    />
                                  </div>
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>
                                        Employer HI Contribution
                                        <span style={{ color: "red" }}>
                                          *
                                        </span>{" "}
                                      </b>
                                    </label>
                                    <Field
                                      name="insurance_cont"
                                      component={Input}
                                      placeholder="Insurance Contribution"
                                    />
                                  </div>

                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>
                                        GPF Contribution{" "}
                                        <span style={{ color: "red" }}>*</span>{" "}
                                      </b>
                                    </label>
                                    <Field
                                      name="gpf_cont"
                                      component={Input}
                                      placeholder="GPF Contribution"
                                    />
                                  </div>

                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>
                                        Days{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </b>
                                    </label>
                                    <Field
                                      name="days"
                                      component={Input}
                                      placeholder="Enter Days"
                                    />
                                  </div>

                                  <div class="form-group col-sm-4 flex-column d-flex"></div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="bg-dark-o-15 font-size-h4 font-weight-bold mb-6 mt-6 text-center">
                                  Permission denied for update salary allowances
                                </div>
                              </>
                            )}

                            <h3 className="m-3">Salary Deduction Details</h3>

                            <div class="row justify-content-between text-left">
                              <div class="form-group col-sm-4 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>
                                    E O B I{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </b>
                                </label>
                                <Field
                                  name="eobi"
                                  component={Input}
                                  placeholder="EOBI"
                                />
                                {/* <input
                                                                        onChange={(e) => seteobi(e.target.value)}
                                                                        type="text"
                                                                        class="form-control placeholder-bg mb-2"
                                                                    /> */}
                              </div>
                              <div class="form-group col-sm-4 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>
                                    E O B I Payments{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </b>
                                </label>
                                <Field
                                  name="eobi_payments"
                                  component={Input}
                                  placeholder="EOBI Payments"
                                />
                                {/* <input
                                                                        onChange={(e) => seteobi(e.target.value)}
                                                                        type="text"
                                                                        class="form-control placeholder-bg mb-2"
                                                                    /> */}
                              </div>
                              <div class="form-group col-sm-4 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>
                                    Income Tax{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </b>
                                </label>
                                <Field
                                  name="income_tax"
                                  component={Input}
                                  placeholder="Income Tax"
                                />
                                {/* <input
                                                                        onChange={(e) => setincome_tax(e.target.value)}
                                                                        type="text"
                                                                        class="form-control placeholder-bg mb-2"
                                                                    /> */}
                              </div>
                              <div class="form-group col-sm-4 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>
                                    Van Charge{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </label>
                                <Field
                                  name="van_charge"
                                  component={Input}
                                  placeholder="Van Charge"
                                />
                              </div>
                              <div class="form-group col-sm-4 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>
                                    Insurance{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </b>
                                </label>
                                <Field
                                  name="insurance"
                                  component={Input}
                                  placeholder="Insurance"
                                />
                              </div>
                              <div class="form-group col-sm-4 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>
                                    Gp Fund{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </b>
                                </label>
                                <Field
                                  name="gp_fund"
                                  component={Input}
                                  placeholder="Gp Fund"
                                />
                              </div>

                              <div class="form-group col-sm-4 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>
                                    Gp Fund Return{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </b>
                                </label>
                                <Field
                                  name="gpf_return"
                                  component={Input}
                                  placeholder="Gp Fund Return"
                                />
                              </div>

                              {/* <div class="form-group col-sm-6 flex-column d-flex">
                                                                <label class="form-control-label">
                                                                    <b>Other <span style={{ color: "red" }}>*</span> </b>
                                                                </label>
                                                                <Field
                                                                    name="other"
                                                                    component={Input}
                                                                    placeholder="Other"
                                                                />
                                                               
                                                            </div> */}

                              <div class="form-group col-sm-4 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>
                                    Welfare Fund{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </b>
                                </label>
                                <Field
                                  name="welfare_fund"
                                  component={Input}
                                  placeholder="Welfare Fund"
                                />
                              </div>

                              <div class="form-group col-sm-4 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>
                                    Other Detuction{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </b>
                                </label>
                                <Field
                                  name="other_deduction"
                                  component={Input}
                                  placeholder="Other Deduction"
                                />
                              </div>
                              <div class="form-group col-sm-4 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>
                                    Child Fee Deduction{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </b>
                                </label>
                                <Field
                                  name="child_fee_deduction"
                                  component={Input}
                                  placeholder="Child Fee Deduction"
                                />
                              </div>
                              <div class="form-group col-sm-4 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>
                                    Loan Refund{" "}
                                    <span style={{ color: "red" }}>*</span>{" "}
                                  </b>
                                </label>
                                <Field
                                  name="loan_refund"
                                  component={Input}
                                  placeholder="Loan Refund"
                                />
                              </div>
                              <div class="form-group col-sm-4 flex-column d-flex"></div>
                            </div>

                            <div class="row justify-content-between text-left">
                              {/* <div class="form-group col-sm-6 flex-column d-flex">
                                                                <label class="form-control-label">
                                                                    <b>Gp Fund <span style={{ color: "red" }}>*</span>  </b>
                                                                </label>
                                                                <Field
                                                                    name="gp_fund "
                                                                    component={Input}
                                                                    placeholder="Gp Fund "
                                                                />
                                                               
                                                            </div> */}
                            </div>

                            <div class="row justify-content-between text-left">
                              <div class="form-group col-sm-6 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>
                                    Select Bank
                                    <span
                                      style={{ color: "red", fontSize: 12 }}
                                    >
                                      *
                                    </span>
                                  </b>
                                </label>
                                <select
                                  className="form-select form-control"
                                  name="sub_account_id"
                                  value={selectedBank}
                                  onChange={(e) => {
                                    setSelectedBank(e.target.value);
                                    // console.log(allbanks);

                                    let bankData = bankList.filter(
                                      (item) => item.id == e.target.value
                                    );

                                    if (bankData.length != 0) {
                                      setallsubaccounts([
                                        ...bankData[0].bank_accounts,
                                      ]);
                                    } else {
                                      setallsubaccounts([]);
                                    }

                                    console.log(allsubaccounts);
                                  }}
                                  style={{ backgroundColor: "white" }}
                                  aria-label="Default select example"
                                >
                                  <option acode="" selected value="">
                                    Select...
                                  </option>
                                  {bankList.map((item) => (
                                    <option value={item.id}>
                                      {item.title}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div class="form-group col-sm-6 flex-column d-flex">
                                <label className="form-control-label">
                                  <b>
                                    Select Bank Branch
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </label>
                                <select
                                  className="form-select form-control"
                                  name="sub_account_id"
                                  value={selectedBankCateogry}
                                  onChange={(e) => {
                                    setSelectedBankCateogry(e.target.value);
                                    // setfeerecevingbank(e.target.value);
                                  }}
                                  style={{ backgroundColor: "white" }}
                                  aria-label="Default select example"
                                >
                                  <option acode="" selected value="">
                                    Select...
                                  </option>
                                  {allsubaccounts.map((item) => (
                                    <option value={item.id}>
                                      {item.bank_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </Form>
                          {isPreviewEnable ? (
                            <div className="row m-3">
                              <h4>Net Pay: {previewValue.net_pay}</h4>
                              <h4>Gross Salary: {previewValue.gross_salary}</h4>
                            </div>
                          ) : null}
                          {showerr ? (
                            <div id="err">
                              <Alert
                                variant="danger"
                                onClose={() => setshowerr(false)}
                                dismissible
                              >
                                <Alert.Heading>
                                  Oops You Got an Error!
                                </Alert.Heading>
                                <p>{showerror}</p>
                              </Alert>
                            </div>
                          ) : null}
                          <div className="row justify-content-between mb-4">
                            <div className="col-md-6">
                              <button
                                type="submit"
                                value="Submit"
                                onClick={() => previewHandle(values)}
                                className="btn btn-primary pl-4 pr-4"
                              >
                                Preview Salary
                              </button>
                            </div>
                            <div className="col-md-6">
                              <label className="checkbox checkbox-single">
                                <input
                                  name="reset_checkbox"
                                  type="checkbox"
                                  onChange={(e) =>
                                    setResetCheck(e.target.checked)
                                  }
                                />
                                <span />
                                &nbsp;&nbsp;&nbsp;Reset Salary to Defined Actual
                                Amount
                              </label>
                            </div>
                          </div>
                          <div class="btns flex_last">
                            <button
                              type="submit"
                              value="Submit"
                              onClick={() => handleSubmit()}
                              //   onClick={updateHandler}
                              class="btn btn-success button_blue pl-4 pr-4"
                            >
                              Update Salary
                            </button>
                            <button
                              onClick={() => show(false)}
                              class="btn_padding_left btn btn-danger btn_width  pl-4 pr-4 ml-3"
                            >
                              Cancel
                            </button>
                          </div>
                        </>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditDefineSallary;
