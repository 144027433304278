import React, { useState, useRef, useEffect } from "react";
import { Divider } from "antd";
import { LinearProgress, Paper } from "@material-ui/core";
import "./SalarySlip.css";
import { baseUrl } from "../../../../services/config";
import { useAlert } from "react-alert";
import ReactToPrint from "react-to-print";
import PasswordModal from "../../../../components/PasswordModal/PasswordModal";
import { upperCase } from "lodash";
import { useSelector } from "react-redux";

const SalarySlip = () => {
  // const loginAccount = JSON.parse(localStorage.getItem("userDetails")).role[0];
  const [code, setCode] = useState("");
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("code");
  const user = useSelector((state) => state.user.user);
  const [campusData, setCampusData] = useState(user.campuses || []);
  const [campusID, setCampusID] = useState("");

  const [reportData, setreportData] = useState([]);
  let authtoken = localStorage.getItem("userToken");
  //for print
  const [printloading, setprintloading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const alert = useAlert();
  const [currentsession, setcurrentsession] = useState("");
  const [allsessions, setallsessions] = useState([]);

  const pageStyle = `
  @page {
    size: A4 !important;
}
`;
  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
    getAllSessions();
  }, []);

  const getAllSessions = () => {
    fetch(`${baseUrl}/session`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setallsessions(data.payload);
        data.payload.forEach((session, index) => {
          if (session.active_financial_year == "1") {
            setcurrentsession(session.id);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMonthName = (date) => {
    if (date == "") {
      return "_";
    }
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    // const d = new Date(date);

    // return `${monthNames[d.getMonth()]}`;
    const d = new Date(date);
    const month = monthNames[d.getMonth()];
    const year = d.getFullYear();

    return `${month}-${year}`;
  };
  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllCampus = async () => {
    await fetch(`${baseUrl}/campus`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCampusData(data.payload);
      })
      .catch((err) => {
        console.log("vehicle", err);
      });
  };

  const handleShow = () => {
    console.log(code);

    if (date == "") {
      alert.error("please select the date");
      return;
    }
    if (selectedType == "campus") {
      if (campusID == "") {
        alert.error("please select the campus");
        return;
      }
    } else {
      if (code == "") {
        alert.error("please Enter Code");
        return;
      }
    }

    setLoading(true);
    const data = {
      session_id: currentsession,
      employee_code: selectedType == "code" ? code : "",
      date: `${date}-01`,
      campus_id: selectedType == "campus" ? campusID : "",
    };
    fetch(`${baseUrl}/staff-monthly-salary-slip`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        console.log(data, "=====");
        if (data.metadata) {
          if (data.metadata.success) {
            if (selectedType == "campus") {
              setreportData(data.payload);
            } else {
              let arr = [];
              arr.push(data.payload);
              setreportData(arr);
            }
            console.log(data);
            // setreportData(data.payload);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err is re", err);
      });
  };
  //for print
  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);
  return (
    <>
      {loading ? <LinearProgress /> : null}
      <Paper
        style={{ marginTop: "10px", marginBottom: "10px", padding: "15px" }}
      >
        <div className="row">
          <div className="col-sm-3">
            <input
              checked={selectedType == "code"}
              onChange={(e) => setSelectedType(e.target.value)}
              type="radio"
              id="code"
              name="selectedType"
              value="code"
            />
            <label for="code">Code Wise</label>
          </div>

          <div className="col-sm-3">
            <input
              type="radio"
              checked={selectedType == "campus"}
              onChange={(e) => setSelectedType(e.target.value)}
              id="campus"
              name="selectedType"
              value="campus"
            />
            <label for="campus">Campus Wise</label>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-3">
            <label>
              <b>Select Session</b>
            </label>
            <select
              onChange={(e) => setcurrentsession(e.target.value)}
              className="form-select"
              style={{ height: 40 }}
              value={currentsession}
              aria-label="Default select example"
            >
              <option value="">Select Session</option>
              {allsessions.map((session, index) => (
                <option key={index} value={session.id}>
                  {session.year}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <label>
              <b>Select Month</b>
            </label>
            <input
              onChange={(e) => setDate(e.target.value)}
              type="month"
              placeholder="Enter employee code"
              className="form-control"
            />
          </div>
          {selectedType == "code" && (
            <div className="col-md-3">
              <input
                onChange={(e) => setCode(e.target.value)}
                type="text"
                placeholder="Enter employee code"
                className="form-control mt-9"
              />
            </div>
          )}

          {selectedType == "campus" && (
            <div className="col-md-3">
              <label>
                <b>Select Campus</b>
              </label>
              <div className="d-flex flex-column">
                <select
                  style={{ height: 40 }}
                  className="form-select"
                  name="year_id"
                  aria-label="Default select example"
                  onChange={(e) => setCampusID(e.target.value)}
                >
                  <option value="">Select Campus</option>
                  {campusData &&
                    campusData.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          )}
          <div className="col-md-2 mt-9 d-flex">
            <button onClick={handleShow} className="btn btn-primary mr-2">
              Search
            </button>
            {reportData.length > 0 ? (
              <ReactToPrint
                documentTitle={`salary slip Report`}
                onAfterPrint={handleAfterPrint}
                pageStyle={pageStyle}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint={true}
                trigger={() => (
                  <button
                    // onClick={() => console.log("dfsdfsdfd")}
                    disabled={printloading ? true : false}
                    style={{ marginLeft: "10px" }}
                    className="btn btn-success button_blue button_blue"
                  >
                    {printloading ? <span>Printing..</span> : "Print"}
                  </button>
                )}
                content={() => componentRef.current}
              />
            ) : null}
          </div>
        </div>
        <Divider />
        {reportData.length > 0
          ? reportData.map((item) => (
              <div className="mainContainer">
                <div className="logocontainer d-flex ">
                  <img style={{ height: 100, width: 110 }} src={icon} />
                  <div className="ml-4">
                    <h1 style={{ fontSize: "30px" }}>{unitName}</h1>
                    <h3 style={{ fontSize: "20px" }}>Staff Salary Slip</h3>
                    <label style={{ fontSize: "18px" }}>
                      Salary Month &nbsp; : &nbsp;
                      <b>
                        {getMonthName(item?.employee_salaries[0]?.salary_month)}
                      </b>
                    </label>
                  </div>
                </div>
                <div className="maindetailsContainer">
                  <div className="leftsalarySlip">
                    <div className="leftlipcard">
                      <span style={{ width: "40%" }}>
                        <h5 style={{ fontSize: "20px" }}>Staff Code:-</h5>
                      </span>
                      <h6 style={{ fontSize: "20px" }}>{item?.emp_code}</h6>
                    </div>
                    <div className="leftlipcard">
                      <span style={{ width: "40%" }}>
                        {" "}
                        <h5 style={{ fontSize: "20px" }}>Name:-</h5>
                      </span>
                      <h6 style={{ fontSize: "20px" }}>{item?.full_name}</h6>
                    </div>
                    <div className="leftlipcard">
                      <span style={{ width: "40%" }}>
                        <h5 style={{ fontSize: "20px" }}>Father's Name:-</h5>
                      </span>
                      <h6 style={{ fontSize: "20px" }}>{item?.father_name}</h6>
                    </div>
                    <div className="leftlipcard">
                      <span style={{ width: "40%" }}>
                        <h5 style={{ fontSize: "20px" }}>Designation:-</h5>
                      </span>
                      <h6 style={{ fontSize: "20px" }}>
                        {item?.designation?.name}
                      </h6>
                    </div>
                    <div className="leftlipcard">
                      <span style={{ width: "40%" }}>
                        <h5 style={{ fontSize: "20px" }}>Pay Scale:-</h5>
                      </span>
                      <h6 style={{ fontSize: "20px" }}>
                        {item?.pay_scale?.payscale}
                      </h6>
                    </div>
                    <div className="leftlipcard">
                      <span style={{ width: "40%" }}>
                        <h5 style={{ fontSize: "20px" }}>Campus:-</h5>
                      </span>
                      <h6 style={{ fontSize: "20px" }}>{item?.campus?.name}</h6>
                    </div>
                    <div className="leftlipcard">
                      <span style={{ width: "40%" }}>
                        <h5 style={{ fontSize: "20px" }}>Payment Type:-</h5>
                      </span>
                      <h6 style={{ fontSize: "20px" }}>{item?.payment_type}</h6>
                    </div>
                    <div className="leftlipcard">
                      <span style={{ width: "40%" }}>
                        <h5 style={{ fontSize: "20px" }}>Date of Joining:-</h5>
                      </span>
                      <h6 style={{ fontSize: "20px" }}>{item?.joining_date}</h6>
                    </div>
                    <div className="leftlipcard">
                      <span style={{ width: "40%" }}>
                        <h5 style={{ fontSize: "20px" }}>Date of Birth:-</h5>
                      </span>
                      <h6 style={{ fontSize: "20px" }}>{item?.dob}</h6>
                    </div>
                    <div className="leftlipcard">
                      <span style={{ width: "40%" }}>
                        <h5 style={{ fontSize: "20px" }}>GPF Total:-</h5>
                      </span>
                      <h6 style={{ fontSize: "20px" }}>
                        {item?.gp_collected_fund?.collected_amount}
                      </h6>
                    </div>
                    <div className="leftlipcard">
                      <span style={{ width: "40%" }}>
                        <h5 style={{ fontSize: "20px" }}>Remaining Loan:-</h5>
                      </span>
                      <h6 style={{ fontSize: "20px" }}>
                        {item?.remaining_loan_amount}
                      </h6>
                    </div>
                    <div className="leftlipcard">
                      <span style={{ width: "40%" }}>
                        <h5 style={{ fontSize: "20px" }}>Salary Days:-</h5>
                      </span>
                      <h6 style={{ fontSize: "20px" }}>
                        {item?.employee_salaries[0]?.days}
                      </h6>
                    </div>
                    <div className="leftlipcard">
                      <span style={{ width: "40%" }}>
                        <h5 style={{ fontSize: "20px" }}>Account No:-</h5>
                      </span>
                      <h6 style={{ fontSize: "20px" }}>{item?.account_no}</h6>
                    </div>
                    <div className="leftlipcard">
                      <span style={{ width: "40%" }}>
                        <h5 style={{ fontSize: "20px" }}>Bank:-</h5>
                      </span>
                      <h6 style={{ fontSize: "20px" }}>
                        {item?.bank_account?.bank_name}
                      </h6>
                    </div>
                    <div className="accountant">
                      <div></div>
                      <h5 style={{ fontSize: "20px" }}>Accountant</h5>
                    </div>
                  </div>
                  <div className="rightSlipconta">
                    <h5 className="paydetailshe">Pay Details</h5>
                    <div className="rightSlip">
                      <div className="rightslipcard">
                        <h5>Basic Pay:</h5>
                        <h6>{item?.employee_salaries[0]?.basic_pay}</h6>
                      </div>
                      <div className="rightslipcard">
                        <h5>Increment Total:</h5>
                        <h6>{item?.employee_salaries[0]?.increment}</h6>
                      </div>
                      <div className="overdevider"></div>
                      <div className="leftlipcard">
                        <h5>Allowances</h5>
                      </div>
                      <div className="rightslipcard">
                        <h5>Additional Allowance:</h5>
                        <h6>
                          {item?.employee_salaries[0]?.additional_allowance}
                        </h6>
                      </div>
                      <div className="rightslipcard">
                        <h5>2nd Shift Allowance:</h5>
                        <h6>{item?.employee_salaries[0]?.second_shift}</h6>
                      </div>
                      <div className="rightslipcard">
                        <h5>College Allowance:</h5>
                        <h6>{item?.employee_salaries[0]?.college}</h6>
                      </div>
                      <div className="rightslipcard">
                        <h5>Hostel Allowance:</h5>
                        <h6>{item?.employee_salaries[0]?.hostel}</h6>
                      </div>
                      <div className="rightslipcard">
                        <h5>Other Allowance:</h5>
                        <h6>{item?.employee_salaries[0]?.other_allowance}</h6>
                      </div>
                      <div className="rightslipcard">
                        <h5>Hod Allowance:</h5>
                        <h6>{item?.employee_salaries[0]?.hod}</h6>
                      </div>
                      <div className="rightslipcard">
                        <h5>Extra Period Allowance:</h5>
                        <h6>{item?.employee_salaries[0]?.extra_period}</h6>
                      </div>
                      <div className="rightslipcard">
                        <h5>GPF Payment:</h5>
                        <h6>{item?.employee_salaries[0]?.gpf_return}</h6>
                      </div>
                      {/* <div className="rightslipcard">
                 <h5>
                  Security Payment:
                 </h5>
                  <h6>22</h6>
              </div> */}
                      <div className="overdevider"></div>
                      <div className="leftlipcard">
                        <h5>Gross Salary</h5>
                      </div>
                      <div className="rightslipcard">
                        <h5>GP Fund:</h5>
                        <h6>{item?.employee_salaries[0]?.gp_fund}</h6>
                      </div>
                      <div className="rightslipcard">
                        <h5>Income Tax:</h5>
                        <h6>{item?.employee_salaries[0]?.income_tax}</h6>
                      </div>
                      <div className="rightslipcard">
                        <h5>EOBI:</h5>
                        <h6>{item?.employee_salaries[0]?.eobi}</h6>
                      </div>
                      <div className="rightslipcard">
                        <h5>Welfare Fund:</h5>
                        <h6>{item?.employee_salaries[0]?.welfare_fund}</h6>
                      </div>
                      <div className="rightslipcard">
                        <h5>Loan Installment:</h5>
                        <h6>{item?.loans[0]?.monthly_loan_installment}</h6>
                      </div>

                      <div className="overdevider"></div>
                      <div className="leftlipcard">
                        <h5>Deductions</h5>
                      </div>
                      <div className="rightslipcard">
                        <h5>Other Deduction:</h5>
                        <h6>{item?.employee_salaries[0]?.other_deduction}</h6>
                      </div>
                      <div className="rightslipcard">
                        <h5>Child Fee Deduction:</h5>
                        <h6>
                          {item?.employee_salaries[0]?.child_fee_deduction}
                        </h6>
                      </div>
                      <div className="rightslipcard">
                        <h5>Insurance Deduction:</h5>
                        <h6>{item?.employee_salaries[0]?.insurance}</h6>
                      </div>
                      <div className="rightslipcard">
                        <h5>Staff Van Fee Deduction:</h5>
                        <h6>{item?.employee_salaries[0]?.van_charge}</h6>
                      </div>

                      <div className="overdevider"></div>
                      <div
                        className="leftlipcard"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5>Net Salary:</h5>
                        <h5>{item?.employee_salaries[0]?.net_pay}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : null}

        {/* //for print///// */}
        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
          className="tablePrint"
        >
          {reportData.length > 0
            ? reportData.map((item) => (
                <div className="mainContainer thisisforpagebreak">
                  <div
                    className="logocontainer"
                    style={{ height: "100px", marginTop: "20px" }}
                  >
                    <div className="d-flex mb-5">
                      <div className="">
                        <img
                          style={{
                            height: 100,
                            width: 110,
                            pasection_compareingLeft: "20px",
                          }}
                          src={icon}
                          alt="logo"
                        />
                      </div>
                      <div
                        style={{
                          pasection_compareingTop: "10px",
                          marginLeft: "30px",
                        }}
                      >
                        <h1 style={{ fontSize: "30px" }}>{unitName}</h1>
                        <h3 style={{ fontSize: "20px" }}>Staff Salary Slip</h3>
                        <label style={{ fontSize: "18px" }}>
                          Salary Month &nbsp; : &nbsp;
                          <b>
                            {getMonthName(
                              item?.employee_salaries[0]?.salary_month
                            )}
                          </b>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="maindetailsContainer">
                    <div className="leftsalarySlip">
                      <div className="leftlipcard">
                        <span style={{ width: "40%" }}>
                          <h5 style={{ fontSize: "20px" }}>Staff Code:-</h5>
                        </span>
                        <h6 style={{ fontSize: "20px" }}>{item?.emp_code}</h6>
                      </div>
                      <div className="leftlipcard">
                        <span style={{ width: "40%" }}>
                          {" "}
                          <h5 style={{ fontSize: "20px" }}>Name:-</h5>
                        </span>
                        <h6 style={{ fontSize: "20px" }}>{item?.full_name}</h6>
                      </div>
                      <div className="leftlipcard">
                        <span style={{ width: "40%" }}>
                          <h5 style={{ fontSize: "20px" }}>Father's Name:-</h5>
                        </span>
                        <h6 style={{ fontSize: "20px" }}>
                          {item?.father_name}
                        </h6>
                      </div>
                      <div className="leftlipcard">
                        <span style={{ width: "40%" }}>
                          <h5 style={{ fontSize: "20px" }}>Designation:-</h5>
                        </span>
                        <h6 style={{ fontSize: "20px" }}>
                          {item?.designation?.name}
                        </h6>
                      </div>
                      <div className="leftlipcard">
                        <span style={{ width: "40%" }}>
                          <h5 style={{ fontSize: "20px" }}>Pay Scale:-</h5>
                        </span>
                        <h6 style={{ fontSize: "20px" }}>
                          {item?.pay_scale?.payscale}
                        </h6>
                      </div>
                      <div className="leftlipcard">
                        <span style={{ width: "40%" }}>
                          <h5 style={{ fontSize: "20px" }}>Campus:-</h5>
                        </span>
                        <h6 style={{ fontSize: "20px" }}>
                          {item?.campus?.name}
                        </h6>
                      </div>
                      <div className="leftlipcard">
                        <span style={{ width: "40%" }}>
                          <h5 style={{ fontSize: "20px" }}>Payment Type:-</h5>
                        </span>
                        <h6 style={{ fontSize: "20px" }}>
                          {item?.payment_type}
                        </h6>
                      </div>
                      <div className="leftlipcard">
                        <span style={{ width: "40%" }}>
                          <h5 style={{ fontSize: "20px" }}>
                            Date of Joining:-
                          </h5>
                        </span>
                        <h6 style={{ fontSize: "20px" }}>
                          {item?.joining_date}
                        </h6>
                      </div>
                      <div className="leftlipcard">
                        <span style={{ width: "40%" }}>
                          <h5 style={{ fontSize: "20px" }}>Date of Birth:-</h5>
                        </span>
                        <h6 style={{ fontSize: "20px" }}>{item?.dob}</h6>
                      </div>
                      <div className="leftlipcard">
                        <span style={{ width: "40%" }}>
                          <h5 style={{ fontSize: "20px" }}>GPF Total:-</h5>
                        </span>
                        <h6 style={{ fontSize: "20px" }}>
                          {item?.gp_collected_fund?.collected_amount}
                        </h6>
                      </div>
                      <div className="leftlipcard">
                        <span style={{ width: "40%" }}>
                          <h5 style={{ fontSize: "20px" }}>Remaining Loan:-</h5>
                        </span>
                        <h6 style={{ fontSize: "20px" }}>
                          {item?.remaining_loan_amount}
                        </h6>
                      </div>
                      <div className="leftlipcard">
                        <span style={{ width: "40%" }}>
                          <h5 style={{ fontSize: "20px" }}>Salary Days:-</h5>
                        </span>
                        <h6 style={{ fontSize: "20px" }}>
                          {item?.employee_salaries[0]?.days}
                        </h6>
                      </div>
                      <div className="leftlipcard">
                        <span style={{ width: "40%" }}>
                          <h5 style={{ fontSize: "20px" }}>Account No:-</h5>
                        </span>
                        <h6 style={{ fontSize: "20px" }}>{item?.account_no}</h6>
                      </div>
                      <div className="leftlipcard">
                        <span style={{ width: "40%" }}>
                          <h5 style={{ fontSize: "20px" }}>Bank:-</h5>
                        </span>
                        <h6 style={{ fontSize: "20px" }}>
                          {item?.bank_account?.bank_name}
                        </h6>
                      </div>
                      <div
                        className="accountant"
                        style={{ marginTop: "-30px" }}
                      >
                        <div></div>
                        <h5 style={{ fontSize: "20px" }}>Accountant</h5>
                      </div>
                    </div>
                    <div className="rightSlipconta">
                      <h5 style={{ fontSize: "20px" }} className="paydetailshe">
                        Pay Details
                      </h5>
                      <div className="rightSlip">
                        <div className="rightslipcard">
                          <h5 style={{ fontSize: "20px" }}>Basic Pay:</h5>
                          <h6 style={{ fontSize: "20px" }}>
                            {item?.employee_salaries[0]?.basic_pay}
                          </h6>
                        </div>
                        <div className="rightslipcard">
                          <h5 style={{ fontSize: "20px" }}>Increment Total:</h5>
                          <h6 style={{ fontSize: "20px" }}>
                            {item?.employee_salaries[0]?.increment}
                          </h6>
                        </div>
                        <div className="overdevider"></div>
                        <div className="leftlipcard">
                          <h5 style={{ fontSize: "20px" }}>Allowances</h5>
                        </div>
                        <div className="rightslipcard">
                          <h5 style={{ fontSize: "20px" }}>
                            Additional Allowance:
                          </h5>
                          <h6 style={{ fontSize: "20px" }}>
                            {item?.employee_salaries[0]?.additional_allowance}
                          </h6>
                        </div>
                        <div className="rightslipcard">
                          <h5 style={{ fontSize: "20px" }}>
                            2nd Shift Allowance:
                          </h5>
                          <h6 style={{ fontSize: "20px" }}>
                            {item?.employee_salaries[0]?.second_shift}
                          </h6>
                        </div>
                        <div className="rightslipcard">
                          <h5 style={{ fontSize: "20px" }}>
                            College Allowance:
                          </h5>
                          <h6 style={{ fontSize: "20px" }}>
                            {item?.employee_salaries[0]?.college}
                          </h6>
                        </div>
                        <div className="rightslipcard">
                          <h5 style={{ fontSize: "20px" }}>
                            Hostel Allowance:
                          </h5>
                          <h6 style={{ fontSize: "20px" }}>
                            {item?.employee_salaries[0]?.hostel}
                          </h6>
                        </div>
                        <div className="rightslipcard">
                          <h5 style={{ fontSize: "20px" }}>Other Allowance:</h5>
                          <h6 style={{ fontSize: "20px" }}>
                            {item?.employee_salaries[0]?.other_allowance}
                          </h6>
                        </div>
                        <div className="rightslipcard">
                          <h5 style={{ fontSize: "20px" }}>Hod Allowance:</h5>
                          <h6 style={{ fontSize: "20px" }}>
                            {item?.employee_salaries[0]?.hod}
                          </h6>
                        </div>
                        <div className="rightslipcard">
                          <h5 style={{ fontSize: "20px" }}>
                            Extra Period Allowance:
                          </h5>
                          <h6 style={{ fontSize: "20px" }}>
                            {item?.employee_salaries[0]?.extra_period}
                          </h6>
                        </div>
                        <div className="rightslipcard">
                          <h5 style={{ fontSize: "20px" }}>GPF Payment:</h5>
                          <h6 style={{ fontSize: "20px" }}>
                            {item?.employee_salaries[0]?.gpf_return}
                          </h6>
                        </div>
                        {/* <div className="rightslipcard">
                   <h5>
                    Security Payment:
                   </h5>
                    <h6>22</h6>
                </div> */}
                        <div className="overdevider"></div>
                        <div className="leftlipcard">
                          <h5 style={{ fontSize: "20px" }}>Gross Salary</h5>
                        </div>
                        <div className="rightslipcard">
                          <h5 style={{ fontSize: "20px" }}>GP Fund:</h5>
                          <h6 style={{ fontSize: "20px" }}>
                            {item?.employee_salaries[0]?.gp_fund}
                          </h6>
                        </div>
                        <div className="rightslipcard">
                          <h5 style={{ fontSize: "20px" }}>Income Tax:</h5>
                          <h6 style={{ fontSize: "20px" }}>
                            {item?.employee_salaries[0]?.income_tax}
                          </h6>
                        </div>
                        <div className="rightslipcard">
                          <h5 style={{ fontSize: "20px" }}>EOBI:</h5>
                          <h6 style={{ fontSize: "20px" }}>
                            {item?.employee_salaries[0]?.eobi}
                          </h6>
                        </div>
                        <div className="rightslipcard">
                          <h5 style={{ fontSize: "20px" }}>Welfare Fund:</h5>
                          <h6 style={{ fontSize: "20px" }}>
                            {item?.employee_salaries[0]?.welfare_fund}
                          </h6>
                        </div>
                        <div className="rightslipcard">
                          <h5 style={{ fontSize: "20px" }}>
                            Loan Installment:
                          </h5>
                          <h6 style={{ fontSize: "20px" }}>
                            {item?.loans[0]?.monthly_loan_installment}
                          </h6>
                        </div>

                        <div className="overdevider"></div>
                        <div className="leftlipcard">
                          <h5 style={{ fontSize: "20px" }}>
                            <b>Deductions</b>
                          </h5>
                        </div>
                        <div className="rightslipcard">
                          <h5 style={{ fontSize: "20px" }}>Other Deduction:</h5>
                          <h6>{item?.employee_salaries[0]?.other_deduction}</h6>
                        </div>
                        <div className="rightslipcard">
                          <h5 style={{ fontSize: "20px" }}>
                            Child Fee Deduction:
                          </h5>
                          <h6 style={{ fontSize: "20px" }}>
                            {item?.employee_salaries[0]?.child_fee_deduction}
                          </h6>
                        </div>
                        <div className="rightslipcard">
                          <h5 style={{ fontSize: "20px" }}>
                            Insurance Deduction:
                          </h5>
                          <h6 style={{ fontSize: "20px" }}>
                            {item?.employee_salaries[0]?.insurance}
                          </h6>
                        </div>
                        <div className="rightslipcard">
                          <h5 style={{ fontSize: "20px" }}>
                            Staff Van Fee Deduction:
                          </h5>
                          <h6 style={{ fontSize: "20px" }}>
                            {item?.employee_salaries[0]?.van_charge}
                          </h6>
                        </div>

                        <div className="overdevider"></div>
                        <div
                          className="leftlipcard"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h5 style={{ fontSize: "20px" }}>Net Salary:</h5>
                          <h5 style={{ fontSize: "20px" }}>
                            {item?.employee_salaries[0]?.net_pay}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
        {/* {loginAccount == "Campus" ? <PasswordModal /> : ""} */}
      </Paper>
    </>
  );
};

export default SalarySlip;
