import React, { useState, useEffect } from "react";
import { baseUrl } from "../../../../../services/config";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { Field, useFormik, Form, Formik } from "formik";
import * as Yup from "yup";
import { Col } from "react-bootstrap";
import { Table, Modal, Alert } from "react-bootstrap";
import {
  Input,
  DatePickerField,
  Select,
} from "../../../../../_metronic/_partials/controls";
import { values } from "lodash-es";
import DefineLoan from "../DefineLoan/DefineLoan";
import { CheckBox } from "@material-ui/icons";
import { PremissionChecker } from "../../../../../services/PremissionChecker";

const DefineSallary = ({ staffDetails, show, reload, campusID }) => {
  const alert = useAlert();
  const authtoken = localStorage.getItem("userToken");
  const user = useSelector((state) => state.user.user);

  const [loading, setloading] = useState(false);
  const [designation_id, setdesignation_id] = useState(
    staffDetails.designation_id
  );
  const [pay_scale_id, setpay_scale_id] = useState(staffDetails.pay_scale_id);
  const [qualification, setqualification] = useState(
    staffDetails.qualification
  );
  const [account_no, setaccount_no] = useState(staffDetails.account_no);
  const [global_bank_id, setglobal_bank_id] = useState(
    staffDetails.global_bank_id
  );
  const [salery_days, setsalery_days] = useState(staffDetails.salery_days);
  const [payment_type, setpayment_type] = useState(staffDetails.payment_type);
  const [hifz, sethifz] = useState(staffDetails.hifz);
  const [hostel, sethostel] = useState("");
  const [college, setcollege] = useState("");
  const [additional, setadditional] = useState("");
  const [increment, setincrement] = useState("");
  const [second_shift, setsecond_shift] = useState("");
  const [ugs, setugs] = useState("");
  const [other, setother] = useState("");
  const [hod, sethod] = useState("");
  const [science, setscience] = useState("");
  const [extra_period, setextra_period] = useState("");
  const [extra_coaching, setextra_coaching] = useState("");
  const [convance, setconvance] = useState("");
  const [eobi, seteobi] = useState(staffDetails.eobi);
  const [income_tax, setincome_tax] = useState("");
  const [insurance, setinsurance] = useState("");
  const [van_charge, setvan_charge] = useState("");

  const [netPay, setNetPay] = useState();
  const [designations, setdesignations] = useState([]);
  const [AllPayScales, setAllPayScales] = useState([]);
  const [bankList, setbankList] = useState([]);
  const [showerr, setshowerr] = useState(false);
  const [showerror, setshowerror] = useState("Please Fill all Fields");
  const [alreadygeneratedsalary, setalreadygeneratedsalary] = useState({});
  const [myValues, setMyValue] = useState({});
  const [overAllTotal, setOverAllTotal] = useState(0);
  const [loanModal, setloanModal] = useState(false);
  // initialValues=  {
  //   issubmitted: false,

  //   // designation_id: staffDetails.designation_id,
  //   pay_scale_id: staffDetails.pay_scale_id == null ? "" : staffDetails.pay_scale_id,
  //   // qualification: staffDetails.qualification,
  //   // account_no: staffDetails.account_no,

  //   // global_bank_id: staffDetails.global_bank_id,
  //   // salery_days: staffDetails.salery_days,
  //   // payment_type: staffDetails.payment_type,
  //   hifz: "",

  //   hostel: "",
  //   college: "",
  //   additional_allowance: "",
  //   increment: "",

  //   second_shift: "",
  //   ugs: "",
  //   hod: "",
  //   other_allowance: "",

  //   science: "",
  //   extra_period: "",
  //   extra_coaching: "",
  //   convance: "",

  //   eobi: "",
  //   income_tax: "",
  //   insurance: "",
  //   van_charge: "",
  //   other: "",
  // }

  // let initialValues = {
  //   issubmitted: false,

  //   // designation_id: staffDetails.designation_id,
  //   pay_scale_id: staffDetails.pay_scale_id == null ? "" : staffDetails.pay_scale_id,
  //   // qualification: staffDetails.qualification,
  //   // account_no: staffDetails.account_no,

  //   // global_bank_id: staffDetails.global_bank_id,
  //   // salery_days: staffDetails.salery_days,
  //   // payment_type: staffDetails.payment_type,
  //   hifz: "",

  //   hostel: "",
  //   college: "",
  //   additional_allowance: "",
  //   increment: "",

  //   second_shift: "",
  //   ugs: "",
  //   hod: "",
  //   other_allowance: "",

  //   science: "",
  //   extra_period: "",
  //   extra_coaching: "",
  //   convance: "",

  //   eobi: "",
  //   income_tax: "",
  //   insurance: "",
  //   van_charge: "",
  //   other: "",
  // }

  // console.log("initialValues==?", initialValues)

  useEffect(() => {
    getSalaryDetail();
    getAlldesignations();
    getAllPayScales();
    getAllBankList();
  }, []);

  const getSalaryDetail = () => {
    fetch(`${baseUrl}/emp-salary-detail/${staffDetails.id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.metadata) {
          if (data.metadata.success) {
            setalreadygeneratedsalary(data.payload);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAlldesignations = () => {
    fetch(`${baseUrl}/designations`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setdesignations(data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const gotoTop = () => {
    window.scrollTo(0, 0);
  };

  const getAllPayScales = () => {
    fetch(`${baseUrl}/payscales`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setAllPayScales(data.payload);
          console.log(data.payload, "this is pay scale");
          // seteobi(data.payload.eobi);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllBankList = () => {
    fetch(`${baseUrl}/bank`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => {
        res.json().then((data) => {
          setbankList(data.payload);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateHandler = (values) => {
    setloading(true);
    const data = {
      pay_scale_id: parseInt(values.pay_scale_id),
      hifz: values.hifz == "" ? 0 : parseInt(values.hifz),
      hostel: values.hostel == "" ? 0 : parseInt(values.hostel),
      college: values.college == "" ? 0 : parseInt(values.college),
      additional_allowance:
        values.additional_allowance == ""
          ? 0
          : parseInt(values.additional_allowance),
      increment: values.increment == "" ? 0 : parseInt(values.increment),
      second_shift:
        values.second_shift == "" ? 0 : parseInt(values.second_shift),
      ugs: values.ugs == "" ? 0 : parseInt(values.ugs),
      other_allowance:
        values.other_allowance == "" ? 0 : parseInt(values.other_allowance),
      hod: values.hod == "" ? 0 : parseInt(values.hod),
      science: values.science == "" ? 0 : parseInt(values.science),
      extra_period:
        values.extra_period == "" ? 0 : parseInt(values.extra_period),
      extra_coaching:
        values.extra_coaching == "" ? 0 : parseInt(values.extra_coaching),
      convance: values.convance == "" ? 0 : parseInt(values.convance),
      insurance_cont:
        values.insurance_cont == "" ? 0 : parseInt(values.insurance_cont),
      eobi: values.eobi == "" ? 0 : parseInt(values.eobi),
      income_tax: values.income_tax == "" ? 0 : parseInt(values.income_tax),
      insurance: values.insurance == "" ? 0 : parseInt(values.insurance),
      van_charge: values.van_charge == "" ? 0 : parseInt(values.van_charge),
      other: values.other == "" ? 0 : parseInt(values.other),
      collected_gp_fund:
        values.collected_amount == "" ? 0 : parseInt(values.collected_amount),
      auto_clear_deduction: values.auto_clear_deduction,
    };
    fetch(`${baseUrl}/define-salary/${staffDetails.id}?_method=PUT`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.json().then((data) => {
          setloading(false);
          show(false);
          reload(campusID);
          if (data.metadata) {
            if (data.metadata.success) {
              alert.success("Salary has been defined");
              setloading(false);
              show(false);
            } else {
              show(false);
              setloading(false);
              const msgArray = Object.values(data.metadata.message);
              msgArray.map((err) => alert.error(err[0]));
            }
          } else {
            show(false);
            setloading(false);
            // const msgArray = Object.values(data.metadata.message);
            // msgArray.map((err) => alert.error(err[0]));
          }
        });
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
        alert.error(err);
        show(false);
      });
  };
  const gettingNetPay = () => {
    console.log(staffDetails?.pay_scale?.basic, "basic");
    console.log(
      alreadygeneratedsalary?.salaryAlowance?.all_allowance_total,
      "All allowance"
    );
    console.log(
      alreadygeneratedsalary?.salaryDeduction?.all_deduction_total,
      "all deduction"
    );
    console.log(staffDetails?.pay_scale?.gp_fund, "gp");
    console.log(staffDetails?.pay_scale?.welfare_fund, "well");

    const basic =
      Number(staffDetails?.pay_scale?.basic) +
      Number(alreadygeneratedsalary?.salaryAlowance?.all_allowance_total);
    const ducduction =
      Number(alreadygeneratedsalary?.salaryDeduction?.all_deduction_total) +
      Number(staffDetails?.pay_scale?.gp_fund) +
      Number(staffDetails?.pay_scale?.welfare_fund);

    return basic - ducduction;
  };

  const calcularteClick = () => {
    // console.log(staffDetails?.pay_scale?.basic,"basic")
    // console.log(alreadygeneratedsalary?.salaryAlowance?.all_allowance_total,"All allowance")
    // console.log(alreadygeneratedsalary?.salaryDeduction?.all_deduction_total,"all deduction")
    // console.log(staffDetails?.pay_scale?.gp_fund,"gp")
    // console.log(staffDetails?.pay_scale?.welfare_fund,"well")

    // const basic=Number(staffDetails?.pay_scale?.basic)+Number(alreadygeneratedsalary?.salaryAlowance?.all_allowance_total)
    // const ducduction=Number(alreadygeneratedsalary?.salaryDeduction?.all_deduction_total)+Number(staffDetails?.pay_scale?.gp_fund)+Number(staffDetails?.pay_scale?.welfare_fund)

    // const basic=Number(staffDetails?.pay_scale?.basic)+
    // return basic-ducduction

    const basic =
      Number(myValues.basic) +
      Number(myValues.hifz) +
      Number(myValues.hostel) +
      Number(myValues.college) +
      Number(myValues.additional_allowance) +
      Number(myValues.second_shift) +
      Number(myValues.increment) +
      Number(myValues.hod) +
      Number(myValues.ugs) +
      Number(myValues.other_allowance) +
      Number(myValues.science) +
      Number(myValues.extra_period) +
      Number(myValues.extra_coaching);
    const ducduction =
      Number(myValues.eobi) +
      Number(myValues.income_tax) +
      Number(myValues.van_charge) +
      Number(myValues.insurance) +
      Number(myValues.other) +
      Number(staffDetails?.pay_scale?.gp_fund) +
      Number(staffDetails?.pay_scale?.welfare_fund);
    setOverAllTotal(basic - ducduction);
  };

  //start here

  const AppointStaffSchema = Yup.object().shape({
    // designation_id: Yup.string().required("Designation is required"),

    // qualification: Yup.string().required("Qualification is required"),

    pay_scale_id: Yup.string().required("Pay Scale is required "),

    // account_no: Yup.string().required("account_no is required "),

    // global_bank_id: Yup.string().required("Bank Is Required"),
    // salery_days: Yup.string().required("Salery Days is required "),
    // payment_type: Yup.string().required("Payment Type is required "),
    hifz: Yup.string()
      .notRequired("Hifz  Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),

    hostel: Yup.string()
      .notRequired("Hostel Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),
    college: Yup.string()
      .notRequired("College is notRequired ")
      .matches(/^[0-9\b]+$/, "Should be number"),
    additional_allowance: Yup.string()
      .notRequired("Additional Allowance is notRequired ")
      .matches(/^[0-9\b]+$/, "Should be number"),
    increment: Yup.string()
      .notRequired("Increment  Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),

    second_shift: Yup.string()
      .notRequired("Second Shift Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),
    ugs: Yup.string()
      .notRequired("ugs is notRequired ")
      .matches(/^[0-9\b]+$/, "Should be number"),
    // other_allowance: Yup.string().notRequired("Other Allowance  is notRequired ").matches(
    //   /^[0-9\b]+$/,
    //   "Should be number"
    // ),

    other_allowance: Yup.string()
      .notRequired("Other Allowance is notRequired ")
      .matches(/^[0-9\b]+$/, "Should be number"),

    hod: Yup.string()
      .notRequired("hod  Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),

    science: Yup.string()
      .notRequired("science Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),
    extra_period: Yup.string()
      .notRequired("Extra Period is notRequired ")
      .matches(/^[0-9\b]+$/, "Should be number"),
    extra_coaching: Yup.string()
      .notRequired("Extra Coaching  is notRequired ")
      .matches(/^[0-9\b]+$/, "Should be number"),
    convance: Yup.string()
      .notRequired("Convance  Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),
    insurance_cont: Yup.string()
      .notRequired("Insurance Contribution  Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),

    eobi: Yup.string()
      .notRequired("EOBI Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),
    income_tax: Yup.string()
      .notRequired("Income Tax is notRequired ")
      .matches(/^[0-9\b]+$/, "Should be number"),
    insurance: Yup.string()
      .notRequired("Insurance is notRequired ")
      .matches(/^[0-9\b]+$/, "Should be number"),
    van_charge: Yup.string()
      .notRequired("Van Charge  Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),
    other: Yup.string()
      .notRequired("other  Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),
    collected_amount: Yup.string()
      .notRequired("Collected GP Fund  Is notRequired")
      .matches(/^[0-9\b]+$/, "Should be number"),
  });

  const createEroors = (errors, touched, isSubmitting, values) => {
    // if (errors.designation_id && touched.designation_id) {
    //   setshowerror(errors.designation_id);
    //   setshowerr(true);
    //   return;
    // }
    // if (
    //   touched.designation_id &&
    //   values.designation_id.toString().length != 0
    // ) {
    //   if (
    //     values.designation_id.toString().length < 1 ||
    //     values.designation_id.toString().length > 30
    //   ) {
    //     setshowerr(true);
    //     setshowerror("Designation id must be between 5 to 10 Charcters");
    //     return;
    //   }
    // }

    if (errors.pay_scale_id && touched.pay_scale_id) {
      setshowerror("Pay scale id is required");
      setshowerr(true);
      return;
    }
    // if (errors.qualification && touched.qualification) {
    //   setshowerror(errors.qualification);
    //   setshowerr(true);
    //   return;
    // }

    // if (errors.account_no && touched.account_no) {
    //   setshowerror(errors.account_no);
    //   setshowerr(true);
    //   return;
    // }

    // if (errors.global_bank_id && touched.global_bank_id) {
    //   setshowerror(errors.global_bank_id);
    //   setshowerr(true);
    //   return;
    // }

    // if (errors.salery_days && touched.salery_days) {
    //   setshowerror(errors.salery_days);
    //   setshowerr(true);
    //   return;
    // }

    // if (errors.payment_type && touched.payment_type) {
    //   setshowerror(errors.payment_type);
    //   setshowerr(true);
    //   return;
    // }

    // if (errors.hifz && touched.hifz) {
    //   setshowerror(errors.hifz);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.hostel && touched.hostel) {
    //   setshowerror(errors.hostel);
    //   setshowerr(true);
    //   return;
    // }

    // if (errors.college && touched.college) {
    //   setshowerror(errors.college);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.additional_allowance && touched.additional_allowance) {
    //   setshowerror(errors.additional_allowance);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.increment && touched.increment) {
    //   setshowerror(errors.increment);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.second_shift && touched.second_shift) {
    //   setshowerror(errors.second_shift);
    //   setshowerr(true);
    //   return;
    // }

    // if (errors.ugs && touched.ugs) {
    //   setshowerror(errors.ugs);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.hod && touched.hod) {
    //   setshowerror(errors.hod);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.other_allowance && touched.other_allowance) {
    //   setshowerror(errors.other_allowance);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.science && touched.science) {
    //   setshowerror(errors.science);
    //   setshowerr(true);
    //   return;
    // }

    // if (errors.extra_period && touched.extra_period) {
    //   setshowerror(errors.extra_period);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.extra_coaching && touched.extra_coaching) {
    //   setshowerror(errors.extra_coaching);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.convance && touched.convance) {
    //   setshowerror(errors.convance);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.eobi && touched.eobi) {
    //   setshowerror(errors.eobi);
    //   setshowerr(true);
    //   return;
    // }

    // if (errors.income_tax && touched.income_tax) {
    //   setshowerror(errors.income_tax);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.insurance && touched.insurance) {
    //   setshowerror(errors.insurance);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.van_charge && touched.van_charge) {
    //   setshowerror(errors.van_charge);
    //   setshowerr(true);
    //   return;
    // }
    // if (errors.other && touched.other) {
    //   setshowerror(errors.other);
    //   setshowerr(true);
    //   return;
    // }

    setshowerr(false);

    if (Object.keys(errors).length === 0) {
      if (values.pay_scale_id != "") {
        if (isSubmitting) {
          if (!values.issubmitted) {
            values.issubmitted = true;

            updateHandler(values);
          }
        }
      }
    }
  };

  const defineLoan = () => {
    setloanModal(true);
  };

  const checkTheValue = (values, handleChange) => {
    setMyValue(values);
    console.log(values, "this is values");
  };

  return (
    <div>
      {loading ? (
        <b style={{ color: "green" }}>
          Salary
          {Object.values(alreadygeneratedsalary).length == 0
            ? "Defining"
            : "Updating"}
          Please Wait <span className="spinner-borderd"></span>
        </b>
      ) : (
        <div>
          <div class="container-fluid">
            <div class="row d-flex justify-content-center ">
              <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                <div>
                  <Formik
                    enableReinitialize={true}
                    initialValues={
                      Object.values(alreadygeneratedsalary).length == 0
                        ? {
                            issubmitted: false,

                            // designation_id: staffDetails.designation_id,
                            pay_scale_id:
                              staffDetails.pay_scale_id == null
                                ? ""
                                : staffDetails.pay_scale_id,
                            // qualification: staffDetails.qualification,
                            // account_no: staffDetails.account_no,

                            // global_bank_id: staffDetails.global_bank_id,
                            // salery_days: staffDetails.salery_days,
                            // payment_type: staffDetails.payment_type,
                            hifz: "0",

                            hostel: "0",
                            college: "0",
                            additional_allowance: "0",
                            increment: "0",

                            second_shift: "0",
                            ugs: "0",
                            hod: "0",
                            other_allowance: "0",

                            science: "0",
                            extra_period: "0",
                            extra_coaching: "0",
                            convance: "0",
                            insurance_cont: "0",

                            eobi: "0",
                            income_tax: "0",
                            insurance: "0",
                            van_charge: "0",
                            other: "0",
                            collected_amount: "0",
                            auto_clear_deduction: false,
                          }
                        : {
                            issubmitted: false,

                            // designation_id: staffDetails.designation_id,
                            pay_scale_id:
                              staffDetails.pay_scale_id == null
                                ? ""
                                : staffDetails.pay_scale_id,
                            // qualification: staffDetails.qualification,
                            // account_no: staffDetails.account_no,

                            // global_bank_id: staffDetails.global_bank_id,
                            // salery_days: staffDetails.salery_days,
                            // payment_type: staffDetails.payment_type,
                            hifz: alreadygeneratedsalary?.salaryAlowance?.hifz,
                            basic: staffDetails?.pay_scale?.basic,
                            hostel:
                              alreadygeneratedsalary?.salaryAlowance?.hostel,
                            college:
                              alreadygeneratedsalary?.salaryAlowance?.college,
                            additional_allowance:
                              alreadygeneratedsalary?.salaryAlowance
                                ?.additional_allowance,
                            increment:
                              alreadygeneratedsalary?.salaryAlowance?.increment,

                            second_shift:
                              alreadygeneratedsalary?.salaryAlowance
                                ?.second_shift,
                            ugs: alreadygeneratedsalary?.salaryAlowance?.ugs,
                            hod: alreadygeneratedsalary?.salaryAlowance?.hod,
                            other_allowance:
                              alreadygeneratedsalary?.salaryAlowance?.other,

                            science:
                              alreadygeneratedsalary?.salaryAlowance?.science,
                            extra_period:
                              alreadygeneratedsalary?.salaryAlowance
                                ?.extra_period,
                            extra_coaching:
                              alreadygeneratedsalary?.salaryAlowance
                                ?.extra_coaching,
                            convance:
                              alreadygeneratedsalary?.salaryAlowance?.convance,
                            insurance_cont:
                              alreadygeneratedsalary?.salaryAlowance
                                ?.insurance_cont,

                            eobi: alreadygeneratedsalary?.salaryDeduction?.eobi,
                            income_tax:
                              alreadygeneratedsalary?.salaryDeduction
                                ?.income_tax,
                            insurance:
                              alreadygeneratedsalary?.salaryDeduction
                                ?.insurance,
                            van_charge:
                              alreadygeneratedsalary?.salaryDeduction
                                ?.van_charge,
                            other:
                              alreadygeneratedsalary?.salaryDeduction?.other,
                            collected_amount:
                              alreadygeneratedsalary?.gp_collected_fund
                                ?.collected_amount,
                            // auto_clear_deduction:
                            //   staffDetails?.auto_clear_deduction,
                            auto_clear_deduction:
                              staffDetails?.auto_clear_deduction === 1,
                          }
                    }
                    validationSchema={AppointStaffSchema}
                    onSubmit={(values) => {}}
                  >
                    {(props) => {
                      const {
                        handleSubmit,
                        errors,
                        touched,
                        setFieldValue,
                        values,
                        isSubmitting,
                        isValid,
                        handleChange,
                        handleBlur,
                      } = props;

                      // setErrorsLis(errors)
                      createEroors(errors, touched, isSubmitting, values);
                      checkTheValue(values, handleChange);

                      return (
                        <>
                          <Form
                            onSubmit={handleSubmit}
                            className="form form-label-right"
                          >
                            <div class="row justify-content-between text-left">
                              <div class="form-group col-sm-6 flex-column d-flex ">
                                <label class="form-control-label">
                                  <b>
                                    Pay Scale{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </b>
                                </label>
                                <select
                                  class="form-select form-control"
                                  name="pay_scale_id"
                                  value={values.pay_scale_id}
                                  onChange={(e) => {
                                    const data = AllPayScales.find(
                                      (item) => item.id == e.target.value
                                    );

                                    setFieldValue(
                                      "pay_scale_id",
                                      e.target.value
                                    );
                                    setFieldValue("basic", data.basic);
                                  }}
                                  onBlur={handleBlur}
                                  aria-label="Default select example"
                                >
                                  <option selected value="">
                                    Select Payscale
                                  </option>

                                  {AllPayScales?.map((payscle) => (
                                    <option value={payscle.id}>
                                      {payscle.payscale}
                                    </option>
                                  ))}
                                </select>
                                {errors.pay_scale_id && touched.pay_scale_id && (
                                  <div
                                    style={{ color: "#f87d8a" }}
                                    className="input-feedback"
                                  >
                                    Pay scale id is required
                                  </div>
                                )}
                              </div>
                            </div>

                            <h3 className="m-3"> Salary Allowance</h3>
                            <div class="row justify-content-between text-left">
                              <div class="form-group col-sm-4 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>Basic Salary</b>
                                </label>
                                <Field
                                  disabled
                                  name="basic"
                                  component={Input}
                                  placeholder="Basic"
                                />
                              </div>
                            </div>

                            {PremissionChecker(
                              user,
                              "appointed_staff.can_update_allowance"
                            ) ? (
                              <>
                                <div class="row justify-content-between text-left">
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>Hifz</b>
                                    </label>
                                    <Field
                                      name="hifz"
                                      component={Input}
                                      placeholder="Hifz"
                                    />
                                  </div>
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>Hostel</b>
                                    </label>
                                    <Field
                                      name="hostel"
                                      component={Input}
                                      placeholder="Hostel"
                                    />
                                  </div>
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>College</b>
                                    </label>
                                    <Field
                                      name="college"
                                      component={Input}
                                      placeholder="College"
                                    />
                                  </div>
                                </div>
                                <div class="row justify-content-between text-left">
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>Additional</b>
                                    </label>
                                    <Field
                                      name="additional_allowance"
                                      component={Input}
                                      placeholder="Additional Allowance"
                                    />
                                  </div>
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>Increment</b>
                                    </label>
                                    <Field
                                      name="increment"
                                      component={Input}
                                      placeholder="increment"
                                    />
                                  </div>
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>Second Shift</b>
                                    </label>
                                    <Field
                                      name="second_shift"
                                      component={Input}
                                      placeholder="Second Shift"
                                    />
                                  </div>
                                </div>
                                <div class="row justify-content-between text-left">
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>UGS</b>
                                    </label>
                                    <Field
                                      name="ugs"
                                      component={Input}
                                      placeholder="UGS"
                                    />
                                  </div>
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>H O D </b>
                                    </label>
                                    <Field
                                      name="hod"
                                      component={Input}
                                      placeholder="HOD"
                                    />
                                  </div>
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>Other allowance</b>
                                    </label>
                                    <Field
                                      name="other_allowance"
                                      component={Input}
                                      placeholder="Other Allowance"
                                    />
                                  </div>
                                </div>

                                <div class="row justify-content-between text-left">
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>Science</b>
                                    </label>
                                    <Field
                                      name="science"
                                      component={Input}
                                      placeholder="Science"
                                    />
                                  </div>
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>Extra Period</b>
                                    </label>
                                    <Field
                                      name="extra_period"
                                      component={Input}
                                      placeholder="Extra Period"
                                    />
                                  </div>
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>Extra Coaching</b>
                                    </label>
                                    <Field
                                      name="extra_coaching"
                                      component={Input}
                                      placeholder="Extra Coaching"
                                    />
                                  </div>
                                </div>

                                <div class="row justify-content-between text-left">
                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>Convance </b>
                                    </label>
                                    <Field
                                      name="convance"
                                      component={Input}
                                      placeholder="Convance"
                                    />
                                  </div>

                                  <div class="form-group col-sm-4 flex-column d-flex">
                                    <label class="form-control-label">
                                      <b>Employer HI Contribution</b>
                                    </label>
                                    <Field
                                      name="insurance_cont"
                                      component={Input}
                                      placeholder="Insurance Contribution"
                                    />
                                  </div>

                                  <div class="form-group col-sm-4 flex-column d-flex"></div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="bg-dark-o-15 font-size-h4 font-weight-bold mb-8 text-center">
                                  Permission denied for update salary allowances
                                </div>
                              </>
                            )}

                            <h3 className="m-3">Salary Deduction Details</h3>

                            <div class="row justify-content-between text-left">
                              <div class="form-group col-sm-4 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>EOBI</b>
                                </label>
                                <Field
                                  name="eobi"
                                  component={Input}
                                  placeholder="EOBI"
                                />
                                {/* <input
                                  type='text'
                                  class='form-control placeholder-bg mb-2'
                                  onChange={(e) => seteobi(e.target.value)}
                                  value={alreadygeneratedsalary.eobi}
                                /> */}
                              </div>
                              <div class="form-group col-sm-4 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>Income Tax </b>
                                </label>
                                <Field
                                  name="income_tax"
                                  component={Input}
                                  placeholder="Income Tax"
                                />
                                {/* <input
                                                                        onChange={(e) => setincome_tax(e.target.value)}
                                                                        type="text"
                                                                        class="form-control placeholder-bg mb-2"
                                                                    /> */}
                              </div>
                              <div class="form-group col-sm-4 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>Van Charge </b>
                                </label>
                                <Field
                                  name="van_charge"
                                  component={Input}
                                  placeholder="Van Charge"
                                />
                              </div>
                            </div>
                            <div class="row justify-content-between text-left">
                              <div class="form-group col-sm-6 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>Insurance </b>
                                </label>
                                <Field
                                  name="insurance"
                                  component={Input}
                                  placeholder="Insurance"
                                />
                                {/* <input
                                                                        onChange={(e) => setinsurance(e.target.value)}
                                                                        type="text"
                                                                        class="form-control placeholder-bg mb-2"
                                                                        required
                                                                    /> */}
                              </div>

                              <div class="form-group col-sm-6 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>Other </b>
                                </label>
                                <Field
                                  name="other"
                                  component={Input}
                                  placeholder="Other"
                                />
                                {/* <input
                                                                        onChange={(e) => setother(e.target.value)}
                                                                        type="text"
                                                                        class="form-control placeholder-bg mb-2"
                                                                    /> */}
                              </div>
                              <div class="form-group col-sm-6 flex-column d-flex">
                                <label class="form-control-label">
                                  <b>Total GP Fund Collected </b>
                                </label>
                                <Field
                                  name="collected_amount"
                                  component={Input}
                                  placeholder="GP Fund Collected"
                                />
                                {/* <input
                                                                        onChange={(e) => setother(e.target.value)}
                                                                        type="text"
                                                                        class="form-control placeholder-bg mb-2"
                                                                    /> */}
                              </div>

                              <div class="form-group col-sm-6 flex-column">
                                <Field
                                  type="checkbox"
                                  name="auto_clear_deduction"
                                  id="auto_clear_deduction"
                                />
                                <label
                                  for="auto_clear_deduction"
                                  class="form-control-label"
                                >
                                  <b>Auto Clear Deduction</b>
                                </label>
                              </div>
                            </div>
                          </Form>
                          {showerr ? (
                            <div id="err">
                              <Alert
                                variant="danger"
                                onClose={() => setshowerr(false)}
                                dismissible
                              >
                                <Alert.Heading>
                                  Oops You Got an Error!
                                </Alert.Heading>
                                <p>{showerror}</p>
                              </Alert>
                            </div>
                          ) : null}
                          <div className="d-flex mb-5">
                            <h4>Net Pay:</h4>
                            <h4>
                              {overAllTotal == 0
                                ? gettingNetPay()
                                : overAllTotal}
                            </h4>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <button
                                style={{ alignSelf: "flex-start" }}
                                className="btn btn-success"
                                onClick={defineLoan}
                              >
                                Salary Loan
                              </button>
                              <button
                                style={{ alignSelf: "flex-start" }}
                                className="btn btn-primary mx-2"
                                onClick={calcularteClick}
                              >
                                Calculate
                              </button>
                            </div>

                            <div class="btns flex_last">
                              <button
                                type="submit"
                                value="Submit"
                                onClick={() => handleSubmit()}
                                // onClick={updateHandler}
                                class="btn btn-success button_blue pl-4 pr-4"
                              >
                                {Object.values(alreadygeneratedsalary).length ==
                                0
                                  ? "Define"
                                  : "Update"}
                              </button>
                              <button
                                onClick={() => show(false)}
                                class="btn_padding_left btn btn-danger btn_width  pl-4 pr-4 ml-3"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <DefineLoan
        visible={loanModal}
        employeDetails={staffDetails}
        closeModal={() => setloanModal(false)}
        reload={reload}
      />
    </div>
  );
};

export default DefineSallary;
