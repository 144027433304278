import { message } from "antd";
import React from "react";
import { useState } from "react";
import { useAlert } from "react-alert";
import "./viewResult.css";
import { Modal, Table } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { baseUrl } from "../../../../../services/config";
import { useEffect } from "react";
import {
  sendNotificationsInBatches,
  sendNotificationsInBatches2,
} from "../../../../../components/SendNotifications/SendNotifications";
import toast, { Toaster } from "react-hot-toast";
import { upperCase } from "lodash";

const ViewResult = ({ modalVisible, hideModal, resultDetails, getter }) => {
  console.log(resultDetails);
  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";
  let authtoken = localStorage.getItem("userToken");
  const componentRef = React.useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const [buttonDisabled, setbuttonDisabled] = useState(false);
  const [studentIds, setStudentIds] = useState([]);
  const [allcheckboxes, setallcheckboxes] = useState([]);
  const [printloading, setprintloading] = React.useState(false);
  const [sortedData, setSortedData] = useState();
  const [text, setText] = React.useState("old boring text");
  const [checkallcheckbox, setcheckallcheckbox] = useState(false);
  const alert = useAlert();
  // console.log("dekhny k qabil h ?", resultDetails)

  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const [unitName, setUnitName] = useState("");
  const [icon, setIcon] = useState([]);

  useEffect(() => {
    getSettingData();
    if (resultDetails) {
      if (resultDetails.students_full_result) {
        let res = [];
        if (
          Array.isArray(
            resultDetails.students_full_result[0]?.student_result_overall
          ) &&
          resultDetails.students_full_result[0]?.student_result_overall.length >
            1
        ) {
          SetPositionsAnnual(resultDetails);
        } else {
          SetPositionsSingleTerm(resultDetails);
        }
      }
    }
    // console.log("use Effect Chala");
  }, [resultDetails]);

  const SetPositionsAnnual = (resultDetails) => {
    const res = resultDetails.students_full_result.sort(
      (a, b) =>
        b.student_result_overall[0]?.gain_marks +
        b.student_result_overall[1]?.gain_marks -
        (a.student_result_overall[0]?.gain_marks +
          a.student_result_overall[1]?.gain_marks)
    );

    let position = 1;

    for (let i = 0; i < res.length; i++) {
      if (res[i].student_result_overall[1]?.full_marks == 0) {
        res[i].student_result_overall[1].position = "-";
      } else {
        if (
          i > 0 &&
          res[i].student_result_overall[0].gain_marks +
            res[i].student_result_overall[1].gain_marks ===
            res[i - 1].student_result_overall[0]?.gain_marks +
              res[i - 1].student_result_overall[1]?.gain_marks
        ) {
          res[i].student_result_overall.position =
            res[i - 1].student_result_overall.position;
        } else {
          // Assign a new position
          res[i].student_result_overall.position = convertOrdinal(position);
        }

        if (
          i < res.length - 1 &&
          res[i].student_result_overall[0].gain_marks +
            res[i].student_result_overall[1].gain_marks !==
            res[i + 1].student_result_overall[0]?.gain_marks +
              res[i + 1].student_result_overall[1]?.gain_marks
        ) {
          convertOrdinal(position++);
        }
      }
    }
    console.log(res, "here we go");
    const finnal = res?.sort(
      (a, b) => a.student.admission_id - b.student.admission_id
    );
    setSortedData(finnal);
  };

  const SetPositionsSingleTerm = (resultDetails) => {
    const res = resultDetails.students_full_result.sort(
      (a, b) =>
        b.student_result_overall?.gain_marks -
        a.student_result_overall?.gain_marks
    );

    let position = 1;

    for (let i = 0; i < res.length; i++) {
      if (res[i].student_result_overall?.full_marks == 0) {
        res[i].student_result_overall.position = "-";
      } else {
        if (
          i > 0 &&
          res[i].student_result_overall.gain_marks ===
            res[i - 1].student_result_overall?.gain_marks
        ) {
          res[i].student_result_overall.position =
            res[i - 1].student_result_overall.position;
        } else {
          // Assign a new position
          res[i].student_result_overall.position = convertOrdinal(position);
        }

        if (
          i < res.length - 1 &&
          res[i].student_result_overall.gain_marks !==
            res[i + 1].student_result_overall?.gain_marks
        ) {
          convertOrdinal(position++);
        }
      }
    }
    console.log(res, "here we go");
    const finnal = res?.sort(
      (a, b) => a.student.admission_id - b.student.admission_id
    );
    setSortedData(finnal);
  };

  const getSettingData = async () => {
    await fetch(`${baseUrl}/get-settings`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authtoken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.payload);
        setUnitName(upperCase(data.payload.settings.unit_name));
        setIcon(data.payload.settings.logo_file);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called");
    setprintloading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setprintloading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setprintloading, setText]);

  // code fro checkboxess
  const allcheckboxChecked = () => {
    let studentarray = [];
    let middlearray = [];
    if (checkallcheckbox) {
      setcheckallcheckbox(false);
      resultDetails.students_full_result.map((item, i) => {
        item.student.checked = false;
        middlearray.push(item.student);
      });
    } else {
      setcheckallcheckbox(true);
      resultDetails.students_full_result.map((item, i) => {
        item.student.checked = true;
        studentarray.push(item.student.id);
        middlearray.push(item.student);
      });
    }
    console.log(studentarray);
    console.log(middlearray);
    setStudentIds([...studentarray]);
  };

  const checkBoxClick = (e, index) => {
    setcheckallcheckbox(true);
    let middlearray = [];
    let studentarray = [];
    resultDetails.students_full_result.map((item, i) => {
      if (item.student.id == index) {
        item.student.checked = !item.student.checked;
      }
      if (!item.student.checked) {
        setcheckallcheckbox(false);
      }
      if (item.student.checked) {
        studentarray.push(item.student.id);
      }
      middlearray.push(item.student);
    });

    console.log(studentarray);
    setStudentIds([...studentarray]);
  };

  const handleModelClose = () => {
    // getter()
    resultDetails.students_full_result.map((item, i) => {
      item.student.checked = false;
    });
    resultDetails.students_full_result.sort(
      (a, b) => a.student.admission_id - b.student.admission_id
    );
    setcheckallcheckbox(false);
    setStudentIds([]);
    hideModal();
  };

  const sendMessage = () => {
    if (studentIds.length > 0) {
      messageApi.open({
        key,
        type: "loading",
        content: "Sending.....",
      });
      setbuttonDisabled(true);
      let data = {
        exam_id: resultDetails.exam.id,
        student_ids: studentIds,
      };

      fetch(`${baseUrl}/student-result-sms`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) =>
          res.json().then((data) => {
            if (data.metadata) {
              if (data.metadata.success) {
                messageApi.open({
                  key,
                  type: "success",
                  content: "Successfully Send",
                  duration: 1,
                });
                setbuttonDisabled(false);
              } else {
                messageApi.open({
                  key,
                  type: "error",
                  content: "Something went wronge",
                  duration: 4,
                });
              }
            }
            console.log(data);
            setbuttonDisabled(false);
          })
        )

        .catch((err) => {
          console.log(err);
        });
    } else {
      alert.error("Please select at least one student");
    }
  };
  const sendNotification = () => {
    if (studentIds.length > 0) {
      // messageApi.open({
      //   key,
      //   type: "success",
      //   content: "Successfully Send",
      //   duration: 1,
      // });

      let FinalNotificationArray = [];
      const res = sortedData.filter((item) =>
        studentIds.includes(item.student.id)
      );
      console.log(res, "after filter");
      res.map((result) => {
        const transformedArray = result.student_result.map((sub) => {
          return `${sub.subject.name}:${sub.gain_marks}/${sub.full_marks}`;
        });
        const resultDec = transformedArray.join(" , ");
        FinalNotificationArray.push({
          title: "Result Alert",
          description: `Dear Parents/Guardian, ${result.student.name} ${resultDetails.exam?.exam_type?.name} result is ${resultDec} Result: ${result.student_result_overall.gain_marks}/${result.student_result_overall.full_marks}`,
          id: result.student.notification_id,
        });
      });
      console.log(FinalNotificationArray, "finnaly");
      // sendNotificationsInBatches2(FinalNotificationArray);
      toast.success("Notification Successfully Sent");
    } else {
      alert.error("Please select at least one student");
    }
  };

  function convertOrdinal(number) {
    const numberString = number.toString();
    const lastDigit = parseInt(numberString.slice(-1));
    const lastTwoDigits = parseInt(numberString.slice(-2));

    if (lastTwoDigits >= 11 && lastTwoDigits <= 20) {
      return numberString + "th";
    }

    switch (lastDigit) {
      case 1:
        return numberString + "st";
      case 2:
        return numberString + "nd";
      case 3:
        return numberString + "rd";
      default:
        return numberString + "th";
    }
  }
  const pageStyle = `
  @page {
margin-left: 1.25in;
    size: legal landscape !important;
}
`;
  const handlePositionSort = () => {
    let sorter = [];

    if (sortedData && Array.isArray(sortedData[0]?.student_result_overall)) {
      sorter = sortedData.sort(
        (a, b) =>
          b.student_result_overall[0]?.gain_marks +
          b.student_result_overall[1]?.gain_marks -
          (a.student_result_overall[0]?.gain_marks +
            a.student_result_overall[1]?.gain_marks)
      );
    } else {
      sorter = sortedData.sort(
        (a, b) =>
          b.student_result_overall?.gain_marks -
          a.student_result_overall?.gain_marks
      );
    }
    console.log("ya challa???");
    console.log(sorter);
    setSortedData([...sorter]);
  };

  const getGrade = (fullmarks, gainmarks) => {
    let obtainMarks = gainmarks / fullmarks;
    let middle = obtainMarks * 100;
    let percentage = middle.toFixed(2);

    if (percentage <= 100 && percentage >= 90) {
      return "A+";
    } else if (percentage < 90 && percentage >= 80) {
      return "A";
    } else if (percentage < 80 && percentage >= 70) {
      return "B";
    } else if (percentage < 70 && percentage >= 60) {
      return "C";
    } else if (percentage < 60 && percentage >= 50) {
      return "D";
    } else if (percentage < 50 && percentage >= 40) {
      return "E";
    } else if (percentage < 40 && percentage >= 0) {
      return "F";
    } else {
      return "Null";
    }
  };

  const getPercentage = (totalmarks, gainMarks) => {
    const percentage = (gainMarks / totalmarks) * 100;
    return percentage.toFixed(1) + "%";
  };

  const printDate = () => {
    const date = new Date();

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };
  return (
    <Modal size={"xl"} onHide={hideModal} show={modalVisible}>
      <Modal.Header closeButton={true}>
        <Modal.Title>Preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Toaster />
        <div className="d-flex m-3">
          <div>
            <ReactToPrint
              documentTitle="Student Wise result"
              pageStyle={pageStyle}
              onAfterPrint={handleAfterPrint}
              onBeforeGetContent={handleOnBeforeGetContent}
              onBeforePrint={handleBeforePrint}
              onPrintError={(e) => console.log(e)}
              removeAfterPrint={true}
              trigger={() => (
                <button
                  disabled={printloading ? true : false}
                  className="btn btn-primary"
                >
                  {printloading ? "Printing..." : "Print"}
                </button>
              )}
              content={() => componentRef.current}
            />
          </div>
          <div className="mx-2">
            <button
              disabled={buttonDisabled}
              onClick={sendMessage}
              className="btn btn-primary"
            >
              Send Message
            </button>
          </div>
          <div className="mx-2">
            <button onClick={sendNotification} className="btn btn-primary">
              Send Notification
            </button>
          </div>
          <div className="mx-2">
            <button onClick={handlePositionSort} className="btn btn-primary">
              Sort By Position
            </button>
          </div>
          <div className="mx-2">
            <button
              onClick={() => {
                const finnal = sortedData.sort(
                  (a, b) => a.student.admission_id - b.student.admission_id
                );
                setSortedData([...finnal]);
              }}
              className="btn btn-primary"
            >
              Sort By Admission No
            </button>
          </div>
        </div>
        {contextHolder}

        <div className="d-flex justify-content-end">
          {resultDetails?.students_full_result &&
          resultDetails.students_full_result.length > 0 ? (
            <div>
              {Array.isArray(
                resultDetails.students_full_result[0]?.student_result_overall
              ) ? (
                <h6 className="text-primary" style={{ fontSize: "1rem" }}>
                  <b>{resultDetails?.exam?.exam_type?.name} TOTAL MARKS: </b>{" "}
                  {Number(
                    resultDetails?.students_full_result[0]
                      ?.student_result_overall[0]?.full_marks
                  )}
                </h6>
              ) : (
                <h6 className="text-primary" style={{ fontSize: "1rem" }}>
                  <b>{resultDetails?.exam?.exam_type?.name} TOTAL MARKS: </b>{" "}
                  {Number(
                    resultDetails?.students_full_result[0]
                      ?.student_result_overall?.full_marks
                  )}
                </h6>
              )}
            </div>
          ) : (
            ""
          )}
        </div>

        <table style={{ border: "1px solid #B8860B", width: "100%" }}>
          {resultDetails?.students_full_result &&
          resultDetails.students_full_result.length > 0 &&
          Array.isArray(
            resultDetails.students_full_result[0]?.student_result_overall
          ) ? (
            <>
              <thead>
                <tr style={{ border: "1px solid #B8860B" }}>
                  <th>
                    <input type="checkbox" style={{ display: "none" }} />
                    <label className="checkbox checkbox-single">
                      <input
                        type="checkbox"
                        onClick={(e) => {
                          allcheckboxChecked();
                        }}
                        checked={checkallcheckbox}
                      />
                      <span />
                    </label>
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bolder",
                      color: "#01069F",
                      borderRight: "1px solid #B8860B",
                      borderLeft: "1px solid #B8860B",
                    }}
                  >
                    Sr.no
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bolder",
                      color: "#01069F",
                      borderRight: "1px solid #B8860B",
                    }}
                  >
                    Adm-ID
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bolder",
                      color: "#01069F",
                      borderRight: "1px solid #B8860B",
                    }}
                  >
                    STUDENT NAME
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bolder",
                      color: "#01069F",
                      borderRight: "1px solid #B8860B",
                    }}
                  >
                    RESULT DETAIL
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bolder",
                      color: "#01069F",
                      borderRight: "1px solid #B8860B",
                    }}
                  >
                    FTM
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bolder",
                      color: "#01069F",
                      borderRight: "1px solid #B8860B",
                    }}
                  >
                    ATM
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bolder",
                      color: "#01069F",
                      borderRight: "1px solid #B8860B",
                    }}
                  >
                    Result
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bolder",
                      color: "#01069F",
                      borderRight: "1px solid #B8860B",
                    }}
                  >
                    %
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bolder",
                      color: "#01069F",
                      borderRight: "1px solid #B8860B",
                    }}
                  >
                    Position
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bolder",
                      color: "#01069F",
                      borderRight: "1px solid #B8860B",
                    }}
                  >
                    Remarks
                  </th>
                </tr>
              </thead>

              <tbody>
                {sortedData?.map((result, index) => (
                  <tr style={{ border: "1px solid #B8860B" }}>
                    <td style={{ textAlign: "start" }}>
                      <input type="checkbox" style={{ display: "none" }} />
                      <label className="checkbox checkbox-single">
                        <input
                          type="checkbox"
                          key={result.student.id}
                          onClick={(e) => {
                            checkBoxClick(e, result?.student.id);
                          }}
                          checked={result?.student.checked}
                          value={allcheckboxes[index]}
                        />
                        <span />
                      </label>
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 10,
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                        borderLeft: "1px solid #B8860B",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 10,
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      {result?.student?.admission_id}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                        fontSize: 10,
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      {result?.student?.name.length > 20
                        ? result?.student?.name.substring(0, 18) + ".."
                        : result?.student?.name}
                    </td>
                    <td
                      style={{
                        // display: "flex",
                        textAlign: "start",
                        fontSize: 10,
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      {result?.student_result?.map((singleres) => (
                        <>
                          {singleres?.subject?.slug}=
                          {Number(singleres?.gain_marks) +
                            Number(singleres?.practical_marks)}
                          {","}&nbsp;
                        </>
                      ))}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 10,
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      {result?.student_result_overall[0]?.exam == "FIRST TERM"
                        ? Number(
                            result?.student_result_overall[0]?.gain_marks
                          ) +
                          Number(
                            result?.student_result_overall[0]?.practical_marks
                          )
                        : Number(
                            result?.student_result_overall[1]?.gain_marks
                          ) +
                          Number(
                            result?.student_result_overall[1]?.practical_marks
                          )}
                    </td>

                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 10,
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      {result?.student_result_overall[1]?.exam == "FIRST TERM"
                        ? Number(
                            result?.student_result_overall[0]?.gain_marks
                          ) +
                          Number(
                            result?.student_result_overall[0]?.practical_marks
                          )
                        : Number(
                            result?.student_result_overall[1]?.gain_marks
                          ) +
                          Number(
                            result?.student_result_overall[1]?.practical_marks
                          )}
                    </td>

                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 10,
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      {result?.student_final_result?.demand_result}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 10,
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      {result?.student_final_result?.percentage}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 10,
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      {result?.student_result_overall?.position}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 10,
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    ></td>
                  </tr>
                ))}
              </tbody>
            </>
          ) : (
            <>
              <thead>
                <tr style={{ border: "1px solid #B8860B" }}>
                  <th>
                    <input type="checkbox" style={{ display: "none" }} />
                    <label className="checkbox checkbox-single">
                      <input
                        type="checkbox"
                        onClick={(e) => {
                          allcheckboxChecked();
                        }}
                        checked={checkallcheckbox}
                      />
                      <span />
                    </label>
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bolder",
                      color: "#01069F",
                      borderRight: "1px solid #B8860B",
                      borderLeft: "1px solid #B8860B",
                    }}
                  >
                    Sr.no
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bolder",
                      color: "#01069F",
                      borderRight: "1px solid #B8860B",
                    }}
                  >
                    Adm-ID
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bolder",
                      color: "#01069F",
                      borderRight: "1px solid #B8860B",
                    }}
                  >
                    Student Name
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bolder",
                      color: "#01069F",
                      borderRight: "1px solid #B8860B",
                    }}
                  >
                    Result Detail
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bolder",
                      color: "#01069F",
                      borderRight: "1px solid #B8860B",
                    }}
                  >
                    Obt Marks
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bolder",
                      color: "#01069F",
                      borderRight: "1px solid #B8860B",
                    }}
                  >
                    Result
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bolder",
                      color: "#01069F",
                      borderRight: "1px solid #B8860B",
                    }}
                  >
                    %
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bolder",
                      color: "#01069F",
                      borderRight: "1px solid #B8860B",
                    }}
                  >
                    Position
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontSize: 10,
                      fontWeight: "bolder",
                      color: "#01069F",
                      borderRight: "1px solid #B8860B",
                    }}
                  >
                    Remarks
                  </th>
                </tr>
              </thead>

              <tbody>
                {sortedData?.map((result, index) => (
                  <tr style={{ border: "1px solid #B8860B" }}>
                    <td style={{ textAlign: "start" }}>
                      <input type="checkbox" style={{ display: "none" }} />
                      <label className="checkbox checkbox-single">
                        <input
                          type="checkbox"
                          key={result.student.id}
                          onClick={(e) => {
                            checkBoxClick(e, result?.student.id);
                          }}
                          checked={result?.student.checked}
                          value={allcheckboxes[index]}
                        />
                        <span />
                      </label>
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 10,
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                        borderLeft: "1px solid #B8860B",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 10,
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      {result?.student?.admission_id}
                    </td>
                    <td
                      style={{
                        textAlign: "start",
                        fontSize: 10,
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      {result?.student?.name.length > 20
                        ? result?.student?.name.substring(0, 18) + ".."
                        : result?.student?.name}
                    </td>
                    <td
                      style={{
                        // display: "flex",
                        textAlign: "center",
                        fontSize: 10,
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      {result?.student_result?.map((singleres) => (
                        <span>
                          {singleres?.subject?.slug}=
                          {Number(singleres?.gain_marks) +
                            Number(singleres?.practical_marks)}
                          {","}&nbsp;
                        </span>
                      ))}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 10,
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      {Number(result?.student_result_overall?.gain_marks) +
                        Number(result?.student_result_overall?.practical_marks)}
                    </td>

                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 10,
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      {result?.student_result_overall?.demand_result}
                      {/* {result?.student_result_overall?.fail_in?.length > 0 ? (
                        result?.student_result_overall?.fail_in
                          .slice(0, 4)
                          .map((failSubject) => (
                            <span>
                              {failSubject}
                              {","}&nbsp;
                            </span>
                          ))
                      ) : (
                        <span>
                          {Number(result?.student_result_overall?.gain_marks) +
                            Number(
                              result?.student_result_overall?.practical_marks
                            )}{" "}
                          -{" "}
                          {getGrade(
                            Number(result?.student_result_overall?.full_marks),
                            Number(result?.student_result_overall?.gain_marks) +
                              Number(
                                result?.student_result_overall?.practical_marks
                              )
                          )}
                        </span>
                      )} */}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 10,
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      {result?.student_result_overall?.percentage}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 10,
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      {result?.student_result_overall?.position}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 10,
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    ></td>
                  </tr>
                ))}
              </tbody>
            </>
          )}
        </table>

        <div
          ref={componentRef}
          id="printSection"
          style={{ visibility: "hidden" }}
        >
          <div className="d-flex p-3 mt-3">
            <img
              // className='col-md-2'
              style={{ height: 100, width: 110 }}
              src={icon}
            />

            <div className="col-md-8">
              <h1>{unitName}</h1>
              <div className="d-flex justify-content-between">
                <div>
                  <h3>
                    <b>Session: {resultDetails?.exam?.session?.year}</b>
                  </h3>

                  <h3>
                    <b>Campus: {resultDetails?.campus?.name}</b>
                  </h3>

                  <h5>
                    <b>Printing Date: {printDate()}</b>
                  </h5>
                </div>
                <div>
                  <h3>
                    <b>Class: {resultDetails?.student_class?.name}</b>
                  </h3>
                  <h3>
                    <b>Section: {resultDetails?.global_section?.name}</b>
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            {resultDetails?.students_full_result &&
            resultDetails.students_full_result.length > 0 ? (
              <div>
                {Array.isArray(
                  resultDetails.students_full_result[0]?.student_result_overall
                ) ? (
                  <h6
                    style={{
                      fontSize: "14px",
                      fontWeight: "bolder",
                      color: "#01069F",
                    }}
                  >
                    <b>{resultDetails?.exam?.exam_type?.name} TOTAL MARKS: </b>{" "}
                    {Number(
                      resultDetails?.students_full_result[0]
                        ?.student_result_overall[0]?.full_marks
                    )}
                  </h6>
                ) : (
                  <h6
                    style={{
                      fontSize: "14px",
                      fontWeight: "bolder",
                      color: "#01069F",
                    }}
                  >
                    <b>{resultDetails?.exam?.exam_type?.name} TOTAL MARKS: </b>{" "}
                    {Number(
                      resultDetails?.students_full_result[0]
                        ?.student_result_overall?.full_marks
                    )}
                  </h6>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <table style={{ border: "1px solid #B8860B", width: "100%" }}>
            {resultDetails?.students_full_result &&
            resultDetails.students_full_result.length > 0 &&
            Array.isArray(
              resultDetails.students_full_result[0]?.student_result_overall
            ) ? (
              <>
                <thead>
                  <tr style={{ border: "1px solid #B8860B" }}>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Sr#
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Adm#
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                        width: "120px",
                      }}
                    >
                      Student Name
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Result Detail
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      FTM
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      ATM
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                        // width: "15%",
                      }}
                    >
                      Result
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      %
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Pos
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        width: "10%",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Remarks
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData?.map((result, index) => (
                    <tr style={{ border: "1px solid #B8860B" }}>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          borderLeft: "1px solid #B8860B",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student?.admission_id}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "start",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          // width: "300px",
                          width: "220px",
                        }}
                      >
                        {result?.student?.name}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          // display: "flex",
                          textAlign: "start",
                          fontSize: "10px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student_result?.map((singleres) => (
                          <span>
                            {singleres?.subject?.slug}={singleres?.gain_marks}
                            {","}&nbsp;
                          </span>
                        ))}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student_result_overall[0]?.exam == "FIRST TERM"
                          ? Number(
                              result?.student_result_overall[0]?.gain_marks
                            ) +
                            Number(
                              result?.student_result_overall[0]?.practical_marks
                            )
                          : Number(
                              result?.student_result_overall[1]?.gain_marks
                            ) +
                            Number(
                              result?.student_result_overall[1]?.practical_marks
                            )}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student_result_overall[1]?.exam == "FIRST TERM"
                          ? Number(
                              result?.student_result_overall[0]?.gain_marks
                            ) +
                            Number(
                              result?.student_result_overall[0]?.practical_marks
                            )
                          : Number(
                              result?.student_result_overall[1]?.gain_marks
                            ) +
                            Number(
                              result?.student_result_overall[1]?.practical_marks
                            )}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "10px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          // width: "15%",
                        }}
                      >
                        {result?.student_final_result?.demand_result}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          // width: "50px",
                        }}
                      >
                        {result?.student_final_result?.percentage}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student_result_overall?.position}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          width: "10%",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            ) : (
              <>
                <thead>
                  <tr style={{ border: "1px solid #B8860B" }}>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Sr#
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Adm#
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                        width: "120px",
                      }}
                    >
                      Student Name
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Result Detail
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#0e :	father1501069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Obt. Marks
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Result
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      %
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                      }}
                    >
                      Pos
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "bolder",
                        color: "#01069F",
                        borderRight: "1px solid #B8860B",
                        width: "10%",
                      }}
                    >
                      Remarks
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData?.map((result, index) => (
                    <tr style={{ border: "1px solid #B8860B" }}>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          borderLeft: "1px solid #B8860B",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student?.admission_id}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "start",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          width: "220px",
                        }}
                      >
                        {result?.student?.name}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          // display: "flex",
                          textAlign: "center",
                          fontSize: "10px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student_result?.map((singleres) => (
                          <span>
                            {singleres?.subject?.slug}={singleres?.gain_marks}
                            {","}&nbsp;
                          </span>
                        ))}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student_result_overall.gain_marks}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {result?.student_result_overall?.demand_result}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          // width: "50px",
                        }}
                      >
                        {result?.student_result_overall?.percentage}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                        }}
                      >
                        {/* {result?.student_result_overall?.full_marks == 0
                        ? ""
                        : convertOrdinal(index + 1)} */}

                        {result?.student_result_overall?.position}
                      </td>
                      <td
                        className="heeLineheight"
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          color: "#01069F",
                          borderRight: "1px solid #B8860B",
                          width: "10%",
                        }}
                      >
                        {""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            )}
          </table>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button onClick={handleModelClose} className="btn btn-danger">
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewResult;
