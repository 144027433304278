export const SendNotifaction = async (
  title,
  des,
  notifaction_name,
  notifaction_id,
  token
) => {
  const notification = {
    body: des,
    title: title,
    notification_name: notifaction_name,
    notification_id: notifaction_id,
  };

  console.log(token);

  try {
    const response = await fetch("https://fcm.googleapis.com/fcm/send", {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "key=AAAAlolfV5g:APA91bEPYJhs18CviA1Ji627rh8oIiHSEVOuJBIfkzKI0yZjo-GRxGnp1VNmT235wxQoawZtFldhlIYcehPXLB16nuhj-YpGqS4pcCTaQ83qMmsJ9KFDuHr3nbWAGsLEXrUnkpUNRW8c",
      },
      method: "POST",
      body: JSON.stringify({
        notification: notification,
        to: `/topics/${token}`,
      }),
    });

    const data = await response.json();
    console.log("response", data);
  } catch (error) {
    console.error("Error making API request:", error);
  }
};

export const sendNotificationsInBatches = async (
  title,
  des,
  notifaction_name,
  notifaction_id,
  ids
) => {
  // Define the batch size (e.g., 10 IDs per batch)
  const batchSize = 10;

  for (let i = 0; i < ids.length; i += batchSize) {
    const batchIds = ids.slice(i, i + batchSize);
    const batchPromises = batchIds.map((id) =>
      SendNotifaction(title, des, notifaction_name, notifaction_id, id)
    );
    await Promise.all(batchPromises);
  }
};

export const sendNotificationsInBatches2 = async (arrayofResult) => {
  // Define the batch size (e.g., 10 IDs per batch)
  const batchSize = 10;

  for (let i = 0; i < arrayofResult.length; i += batchSize) {
    const batchIds = arrayofResult.slice(i, i + batchSize);
    const batchPromises = batchIds.map((item) =>
      SendNotifaction(item.title, item.description, item.id)
    );
    await Promise.all(batchPromises);
  }
};

export const sendExamNotifications = async (arrayofExam) => {
  // Define the batch size (e.g., 10 IDs per batch)
  const batchSize = 10;

  for (let i = 0; i < arrayofExam.length; i += batchSize) {
    const batchIds = arrayofExam.slice(i, i + batchSize);
    const batchPromises = batchIds.map((item) =>
      SendNotifaction(item.title, item.description, "exam", item.id)
    );
    await Promise.all(batchPromises);
  }
};

export const sendNotificationsInBatches3 = async (
  selectAttendace,
  arrayofstudents
) => {
  // Define the batch size (e.g., 10 IDs per batch)
  console.log("ya chala?");
  const batchSize = 10;

  for (let i = 0; i < arrayofstudents.length; i += batchSize) {
    const batchIds = arrayofstudents.slice(i, i + batchSize);
    const batchPromises = batchIds.map((item) => {
      switch (selectAttendace) {
        case "1":
          SendNotifaction(
            "Attendance/Discipline Alert",
            `Dear Parent, your child ${item.name} is absent from school today without any information. Please contact at the earliest`,
            item.id
          );
          break;

        case "2":
          SendNotifaction(
            "Attendance/Discipline Alert",
            `Dear Parent, application for your child ${item.name} sick leave has been received, may he/she get well soon and attend the school at earliest`,
            item.id
          );
          break;
        case "3":
          SendNotifaction(
            "Attendance/Discipline Alert",
            `Dear Parent, Leave Application of your child ${item.name} has been received in the school office and approved.`,
            item.id
          );
          break;
        case "4":
          SendNotifaction(
            "Attendance/Discipline Alert",
            `Dear Parent, your child ${item.name} has been late from school today. Kindly pay attention on his/her discipline`,
            item.id
          );
          break;

        case "5":
          SendNotifaction(
            "Attendance/Discipline Alert",
            `Dear Parent, your child ${item.name} home work was not complete today, Kindly pay attention on his/her studies`,
            item.id
          );
          break;

        case "6":
          SendNotifaction(
            "Attendance/Discipline Alert",
            `Dear Parent, your child ${item.name} was not in proper uniform today. Please send your child in proper uniform.`,
            item.id
          );
          break;

        case "7":
          SendNotifaction(
            "Attendance Alert",
            `Dear Parent, your child ${item.name} test was not prepared. Please make sure he/she must focus on his/her studies.`,
            item.id
          );
          break;
      }
    });
    await Promise.all(batchPromises);
  }
};
