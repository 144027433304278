import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { baseUrl } from "../../../../services/config";
import { useAlert } from "react-alert";
import { sendExamNotifications } from "../../../../components/SendNotifications/SendNotifications";

const Toggle = ({
  url,
  show,
  close,
  title,
  examTitle,
  status,
  id,
  reload,
  dateSheetToggle,
}) => {
  const [loading, setloading] = useState(false);
  const [notifyModal, setNotifyModal] = useState(false);

  const [notificationId, setNotificationId] = useState("");
  const [examData, setExamData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const authtoken = localStorage.getItem("userToken");
  const alert = useAlert();

  const updateExamStatus = () => {
    const data = {
      exam_id: id,
      status: status,
      date_sheet_status: dateSheetToggle,
      notification: notificationId ? 1 : 0,
    };
    setloading(true);
    let fetchurl = `${baseUrl}/${url}`;
    console.log(fetchurl);

    fetch(fetchurl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authtoken}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false);
        if (data.metadata.success) {
          console.log(data);
          setExamData(data.payload);
          close();
          alert.success("Status update successfully");
          reload();
        } else {
          alert.error("opps.. something went wronge");
        }
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });

    if (notificationId == 1) {
      fetch(`${baseUrl}/result-notification`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authtoken}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.metadata.success) {
            console.log(data);
            setStudentData(data.payload);
            setNotifyModal(true);
          } else {
            alert.error("opps.. something went wronge");
          }
        })
        .catch((err) => {
          console.log(err);
          setloading(false);
        });
    }
  };

  const sendNotification = () => {
    if (studentData.length > 0) {
      let FinalNotificationArray = [];

      studentData.map((student) => {
        FinalNotificationArray.push({
          title: "Result Alert",
          description: `Dear Parents/Guardian, the ${examData?.examTitle} exam results are now available. Please check the app.`,
          id: student?.notification_id,
        });
      });
      console.log(FinalNotificationArray, "finnaly");
      sendExamNotifications(FinalNotificationArray);
      toast.success("Notification Successfully Sent");
    } else {
      alert.error("Please select at least one student");
    }
  };

  return (
    <>
      {/* Update Status of Exam */}
      <Modal show={show} onHide={close} animation={true}>
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="contained-modal-title-vcenter"
          >
            {title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="font-size-lg pl-3">
            Are you sure you want to Announce the Result of <b>{examTitle}</b>{" "}
            Exam?
          </p>
          {status == 1 ? (
            <div>
              <label className="checkbox checkbox-single">
                <input
                  type="checkbox"
                  onChange={(e) => setNotificationId(e.target.checked)}
                />
                <span />
                &nbsp;&nbsp;&nbsp;Send Notification to Students
              </label>
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <button
            disabled={loading}
            onClick={updateExamStatus}
            className="btn btn-primary"
          >
            Change
          </button>
          <button onClick={close} className="btn btn-danger">
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={notifyModal}
        onHide={() => setNotifyModal(false)}
        animation={true}
      >
        <Modal.Header className="modal_header_style" closeButton>
          <Modal.Title
            className="Modal_Heading_font"
            id="contained-modal-title-vcenter"
          >
            Send Notification to Students
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="font-size-lg pl-3">
            Are you sure you want to send notification to Students?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            disabled={loading}
            onClick={sendNotification}
            className="btn btn-primary"
          >
            Send Notification
          </button>
          <button
            onClick={() => setNotifyModal(false)}
            className="btn btn-danger"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Toggle;
